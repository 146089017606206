import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.header`
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	background-color: white;
	padding: 0 5%;
	width: 100%;
	z-index: 4;
	> div {
		display: flex;
		> * {
			padding-left: 3rem;
			color: #a19daf;
			stroke: #a19daf;
		}
		> p:hover {
			color: black;
			transition: color 0.3s;
			cursor: pointer;
		}
	}
	> p {
		cursor: pointer;
	}
`

const HeaderWrapper = styled.div`
	position: absolute;
	bottom: 0;
	height: 5px;
	width: 100%;
	left: 0;
	background: linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 100%
	);
`

const StyledSVG = styled.svg`
	padding: 0 !important;
	margin: 1rem 0 0 3rem;
`

const Header = ({ setValues, values }) => {
	return (
		<StyledHeader>
			<p
				onClick={() => {
					setValues({
						...values,
						isHomePage: true,
						shapeMenu: { isOpen: false, selected: null },
					})
				}}
			>
				BouncyThings
			</p>
			<div>
				<a href='#tutorial'>Tutorial</a>
				<a href='#templates'>Templates</a>
				<p>Features</p>
				<p>FAQs</p>
				<StyledSVG width='24.171' height='18' viewBox='0 0 24.171 18'>
					<g id='Hamb' transform='translate(0.998 1)'>
						<path
							id='Line_3'
							data-name='Line 3'
							d='M15192.767,13475.64h-13.014'
							transform='translate(-15179.751 -13459.64)'
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
						/>
						<path
							id='Line_3-2'
							data-name='Line 3'
							d='M15192.767,13475.64h-13.014'
							transform='translate(-15171.253 -13475.64)'
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
						/>
						<path
							id='Line_2'
							data-name='Line 2'
							d='M15201.429,13475.64h-21.673'
							transform='translate(-15179.256 -13467.64)'
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
						/>
					</g>
				</StyledSVG>
			</div>
			<HeaderWrapper />
		</StyledHeader>
	)
}

export default Header
