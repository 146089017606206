import React from 'react'
import Controls from './Controls'
import Record from './Record'
import styled from 'styled-components'

const StyledToolsContainer = styled.div`
	width: 100%;
	padding: 0 5%;
	bottom: 10rem;
	position: absolute;
	display: flex;
	justify-content: space-between;
	pointer-events: none;
`

const Tools = ({ currentTimeSegment }) => {
	return (
		<StyledToolsContainer>
			<Controls currentTimeSegment={currentTimeSegment} />
			<Record />
		</StyledToolsContainer>
	)
}

export default Tools
