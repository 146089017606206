import { v4 as uuidv4 } from 'uuid'

export const add = (context, currentTimeSegment, shape, content) => {
	const {
		values: {
			canvas: {
				timeLine,
				size: { width, height },
			},
		},
		functions: {
			shapeMenu: { open },
		},
	} = context
	let shapeSize = 50
	const shapeId = uuidv4()

	const appel = timeLine.map((segment, i) => [
		...segment,
		{
			id: shapeId,
			r: shape === 'circle' ? shapeSize / 2 : null,
			rx: shape === 'circle' ? null : 0,
			width: shape === 'square' ? shapeSize : null,
			height: shape === 'square' ? shapeSize : null,
			stroke: '#dddddd',
			strokeWidth: 0,
			// rotate: shape === 'circle' ? null : 0,
			opacity: i === currentTimeSegment ? 1 : 0.1,
			htmlName:
				shape === 'square' ? 'rect' : shape === 'circle' ? 'circle' : 'g',
			transform: 'translate(0, 0)',
			transformOrigin: '50% 50%',
			cx: shape === 'circle' ? width / 2 : null,
			cy: shape === 'circle' ? height / 2 : null,
			x: shape === 'circle' ? null : height / 2 - shapeSize / 2,
			y: shape === 'circle' ? null : height / 2 - shapeSize / 2,
			scale: shape === 'svg' ? 1 : null,
			shape,
			content,
			fill: 'black',
		},
	])

	open(shapeId, appel)
}
