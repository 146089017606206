import Shape from '../components/software/Shape'

export const generateDownload = (
	timeLine,
	durationPerSegment,
	canvasSize,
	totalTimeAnimation,
	animationType
) => {
	const html = generateHTML(
		timeLine,
		durationPerSegment,
		canvasSize,
		totalTimeAnimation,
		animationType
	)

	const element = document.createElement('a')
	element.id = 'downloadButton'
	const file = new Blob([html], { type: 'text/plain' })
	element.href = URL.createObjectURL(file)
	element.download = 'myBouncyThing.html'
	document.body.appendChild(element) // Required for this to work in FireFox
	element.click()
	// https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
	document.getElementById('downloadButton').remove()
}

export const generateHTML = (
	timeLine,
	durationPerSegment,
	canvasSize,
	totalTimeAnimation,
	animationType,
	returnSvgOnly
) => {
	console.log(JSON.stringify({ template1: timeLine }))

	const { width, height } = canvasSize
	let content = ''
	const allShapeIds = []

	timeLine[0].forEach((shapes, i) => {
		const {
			props: { id, htmlName, shape, content: c },
		} = <Shape i={i} {...shapes} />
		allShapeIds.push(id)
		if (shape === 'svg') {
			content += `
				<${htmlName} id="BT${id}">${c}</${htmlName}>
			`
		} else {
			content += `
				<${htmlName} id="BT${id}"></${htmlName}>
			`
		}
	})

	const header = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>My BouncyThing</title>
        <style>
        ${generateCSS(
					timeLine,
					durationPerSegment,
					totalTimeAnimation,
					animationType
				)}
        </style>        
    </head>
    <body>
    <svg width="${width}" height="${height}" viewbox="0 0 ${width} ${height}">`

	const footer = `
    </svg>
    </body>
	</html>`

	if (returnSvgOnly) {
		return {
			style: generateCSS(
				timeLine,
				durationPerSegment,
				totalTimeAnimation,
				animationType
			),
			content: content,
		}
	} else {
		return `${header}${content}${footer}`
	}
}

export const generateCSS = (
	timeLine,
	durationPerSegment,
	totalTimeAnimation,
	animationType
) => {
	let allShapes = timeLine[0].map((shape) => {
		return { ...shape }
	})
	let css = ''

	// timeline veranderen in array van shapes
	timeLine.forEach((segment, i) => {
		if (i > 0) {
			segment.forEach((shape, shapeIndex) => {
				Object.entries(shape).forEach((property) => {
					const propertyName = property[0]
					const propertyValue = property[1]
					// if (Array.isArray(allShapes[shapeIndex][propertyName])) {
					// 	const arr = []
					// 	timeLine.forEach((segment) => {
					// 		console.log(segment[shapeIndex])
					// 		segment[shapeIndex][propertyName].forEach((propValue) => {
					// 			arr.push(segment[shapeIndex][propertyName])
					// 		})
					// 	})
					// 	allShapes[shapeIndex][propertyName] = arr

					// 	allShapes[shapeIndex][propertyName][i] = propertyValue[i]
					// } else
					if (allShapes[shapeIndex][propertyName] !== propertyValue) {
						const arr = []
						timeLine.forEach((segment) => {
							arr.push(segment[shapeIndex][propertyName])
						})
						allShapes[shapeIndex][propertyName] = arr
						// allShapes[shapeIndex][propertyName] = [
						// 	allShapes[shapeIndex][propertyName],
						// 	propertyValue,
						// ]
					} else {
						return
					}
				})
			})
		} else {
			return
		}
	})

	// Alle undefined en null weg filteren
	const allShapesWithoutFalsy = allShapes.map((shape, i) => {
		return Object.fromEntries(
			Object.entries(shape).filter((property) => {
				const propertyValue = property[1]
				if (propertyValue === undefined || propertyValue === null) {
					return false
				} else {
					return true
				}
			})
		)
	})

	// Namen van waardes veranderen en suffix geven als nodig
	const parsedShapes = allShapesWithoutFalsy.map((shape, shapeIndex) => {
		return Object.fromEntries(
			Object.entries(shape).map((property) => {
				const nameChangeCheck = checkIfNameChange(property)
				const suffixCheck = checkIfNeedSuffix(nameChangeCheck)
				return suffixCheck
			})
		)
	})

	parsedShapes.forEach((shape, shapeIndex) => {
		let newTransformValues = ''
		if (shape.shape === 'svg') {
			let keyFrameAmount = 0
			let arrayOfPropertyValuesWithArray = Object.entries(
				shape
			).filter((svgProp) => Array.isArray(svgProp[1]))
			keyFrameAmount =
				arrayOfPropertyValuesWithArray.length > 0
					? arrayOfPropertyValuesWithArray[0].length
					: 0
			Object.entries(shape).forEach((svgProp) => {
				const svgPropName = svgProp[0]
				const svgPropValue = svgProp[1]
				if (
					checkIfNeedsTransform(svgPropName) &&
					!Array.isArray(svgPropValue) &&
					keyFrameAmount > 0
				) {
					let newTransArr = []
					for (let index = 0; index < keyFrameAmount; index++) {
						newTransArr = [...newTransArr, svgPropValue]
					}
					parsedShapes[shapeIndex][svgPropName] = newTransArr
				} else if (
					keyFrameAmount === 0 &&
					checkIfNeedsTransform(svgPropName) &&
					!Array.isArray(svgPropValue)
				) {
					newTransformValues += `${checkTransformName(
						svgPropName
					)}(${svgPropValue}) `
				}
			})
		}

		newTransformValues = ''
		Object.entries(shape).forEach((property) => {
			const propertyName = property[0]
			const propertyValue = property[1]
			if (checkIfNeedsTransform(propertyName) && Array.isArray(propertyValue)) {
				propertyValue.forEach((keyframeValue, i) => {
					if (Array.isArray(newTransformValues)) {
						if (newTransformValues[i]) {
							newTransformValues[i] += `${checkTransformName(propertyName)}(${
								propertyValue[i]
							}) `
						} else {
							newTransformValues = [
								...newTransformValues,
								`${checkTransformName(propertyName)}(${propertyValue[i]}) `,
							]
						}
					} else {
						newTransformValues = [
							(newTransformValues += `${checkTransformName(propertyName)}(${
								propertyValue[i]
							}) `),
						]
					}
				})
			} else return
			// if (
			// 	checkIfNeedsTransform(propertyName) &&
			// 	!Array.isArray(propertyValue)
			// ) {
			// 	if (newTransformValues) {
			// 		newTransformValues += `${checkTransformName(
			// 			propertyName
			// 		)}(${propertyValue}) `
			// 	} else {
			// 		newTransformValues = `${checkTransformName(
			// 			propertyName
			// 		)}(${propertyValue}) `
			// 	}
			// }
		})
		parsedShapes[shapeIndex].transform = newTransformValues
	})

	parsedShapes.forEach((shape) => {
		let staticCss = ''
		let animationCss = ''
		let needsAnimation = false
		let startPointOfAnimation = 0
		const animatableCss = []

		Object.entries(shape).forEach((property) => {
			if (!checkIfForbidden(property)) {
				if (Array.isArray(property[1])) {
					property[1].forEach((prop, i) => {
						if (animatableCss[i]) {
							animatableCss[i] += `${property[0]}: ${prop}; `
						} else {
							animatableCss[i] = [`${property[0]}: ${prop}; `]
						}
					})
				} else {
					staticCss += `${property[0]}: ${property[1]}; `
				}
			} else {
				return
			}
		})

		if (animatableCss.length > 1) {
			animatableCss.forEach((keyframe, i) => {
				needsAnimation = true
				animationCss += `
	${startPointOfAnimation}% {
		${animatableCss[i]}
	}
				`
				startPointOfAnimation =
					i === durationPerSegment.length - 1
						? (startPointOfAnimation = 100)
						: startPointOfAnimation +
						  (durationPerSegment[i] / totalTimeAnimation) * 100
			})
		}

		css += `
#BT${shape.id} {
	${staticCss}
	${
		needsAnimation
			? `animation: BT${shape.id} ${totalTimeAnimation}ms ${
					animationType ? animationType : 'infinite'
			  };`
			: ''
	}
}

${
	needsAnimation
		? `
@keyframes BT${shape.id} {
	${animationCss}
}
`
		: ``
}
`
	})
	return css
}

const checkIfForbidden = (property) => {
	const arrayOfForbiddenProperties = [
		'id',
		'htmlName',
		'shape',
		'cx',
		'cy',
		'x',
		'y',
		'rotate',
		'scale',
		'content',
	]

	const arrayOfAllowedWhenValueNotAnArray = ['cx', 'cy', 'x', 'y', 'rotate']

	if (
		arrayOfAllowedWhenValueNotAnArray.includes(property[0]) &&
		!Array.isArray(property[1])
	) {
		return false
	} else {
		return arrayOfForbiddenProperties.includes(property[0])
	}
}

const checkIfNameChange = (property) => {
	const arrayOfNameChange = ['strokeWidth', 'transformOrigin']
	const arrayOfCorrectName = ['stroke-width', 'transform-origin']
	if (arrayOfNameChange.includes(property[0])) {
		return [
			arrayOfCorrectName[arrayOfNameChange.indexOf(property[0])],
			property[1],
		]
	} else {
		return property
	}
}

const checkIfNeedSuffix = (property) => {
	const name = property[0]
	const value = property[1]

	const arrayOfThingsThatNeedSuffixes = [
		'width',
		'height',
		'y',
		'x',
		'rx',
		'cy',
		'cx',
		'r',
		'stroke-width',
		'rotate',
	]
	const suffixYallNeed = [
		'px',
		'px',
		'px',
		'px',
		'px',
		'px',
		'px',
		'px',
		'px',
		'deg',
	]

	if (arrayOfThingsThatNeedSuffixes.includes(name)) {
		if (Array.isArray(value)) {
			return [
				name,
				value.map(
					(val) =>
						`${val || 0}${
							suffixYallNeed[arrayOfThingsThatNeedSuffixes.indexOf(name)]
						}`
				),
			]
		} else {
			return [
				name,
				`${value}${
					suffixYallNeed[arrayOfThingsThatNeedSuffixes.indexOf(name)]
				}`,
			]
		}
	} else {
		return property
	}
}

const checkIfNeedsTransform = (name) => {
	const propsThatNeedTransforming = ['rotate', 'x', 'y', 'cy', 'cx', 'scale']
	return propsThatNeedTransforming.includes(name)
}

const checkTransformName = (name) => {
	const propertyNames = ['x', 'cx', 'cy', 'y']
	const animationNames = [
		'translateX',
		'translateX',
		'translateY',
		'translateY',
	]

	if (propertyNames.includes(name)) {
		return animationNames[propertyNames.indexOf(name)]
	} else {
		return name
	}
}
