import React, { createContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

// Styling
import './App.css'

// Helpers
import { transformIcon } from './helpers/transformIcon'
import { moveArrayPositions } from './helpers/shapeMenu'

// Components
import Canvas from './components/software/Canvas'
import GettingStartedModals from './components/software/zeroState/GettingStartedModals'
import Tools from './components/software/buttons/Tools'
import ShapeMenu from './components/software/ShapeMenu'
import TimeLine from './components/software/TimeLine'
import Export from './components/software/buttons/Export'
import { generateLivePreview } from './helpers/livePreview'
import Header from './components/layout/Header'
import HomePage from './components/layout/HomePage'

export const Context = createContext({})

const App = () => {
	const [currentTimeSegment, setCurrentTimeSegment] = useState(0)
	const [livePreview, setLivePreview] = useState(null)
	const [codeIsVisible, setCodeIsVisible] = useState(false)
	const [canvasScale, setCanvasScale] = useState(1)

	const [values, setValues] = useState({
		isHomePage: true,
		shapeMenu: {
			isOpen: false,
			selected: null,
		},
		animation: {
			totalTimeAnimation: 1000,
			durationPerSegment: [1000],
			animationType: 'infinite',
		},
		canvas: {
			timeLine: [[]],
			size: {
				width: 300,
				height: 300,
			},
		},
		transformControls: [
			// { name: 'select', funcName: 'select', icon: transformIcon.select },
			{
				name: 'add shape',
				funcName: 'addShape',
				icon: transformIcon.addShape,
				extraFuncs: [
					{ name: 'circle', funcName: 'circle', icon: transformIcon.circle },
					{ name: 'square', funcName: 'square', icon: transformIcon.square },
				],
			},
		],
		mouseDown: false,
		shapeInfo: {
			shapeId: null,
			shapeType: null,
			shapeWidth: null,
			shapeHeight: null,
		},
	})

	const functions = {
		setValues: (name, val) => {
			setValues({ ...values, [name]: val })
		},
		setMultipleValues: (v) => {
			setValues({ ...values, ...v })
		},
		animation: {
			changeAnimationDuration: ({ target: { value: newDuration } }) => {
				setValues({
					...values,
					animation: { ...values.animation, totalTimeAnimation: newDuration },
				})
			},
			calculatedurationPerSegment: (length) => {
				const timeArray = []
				const newdurationPerSegment =
					values.animation.totalTimeAnimation / length

				for (let index = 0; index < length; index++) {
					timeArray.push(newdurationPerSegment)
				}

				return timeArray
			},
			setAnimationType: (value) => {
				setValues({
					...values,
					animation: {
						...values.animation,
						animationType: value,
					},
				})
			},
		},
		canvas: {
			duplicateSegment: (
				indexOfSegmentThatNeedsACopy,
				indexOfWhereToPasteAfter
			) => {
				const { timeLine } = values.canvas
				const copyOfSegment = timeLine[indexOfSegmentThatNeedsACopy]
				if (timeLine.length - 1 === indexOfWhereToPasteAfter) {
					const timeLineWithNewSegment = [...timeLine, copyOfSegment]
					functions.canvas.updateTimeLine(timeLineWithNewSegment)
				} else {
					timeLine.splice(indexOfWhereToPasteAfter + 1, 0, copyOfSegment)
					functions.canvas.updateTimeLine(timeLine)
				}
			},
			removeSegment: (index) => {
				setCurrentTimeSegment(0)
				const copyOfTimeLine = values.canvas.timeLine
				copyOfTimeLine.splice(index, 1)
				functions.canvas.updateTimeLine(copyOfTimeLine)
			},
			updateTimeLine: (newTimeLine) => {
				console.log(newTimeLine.length)
				const durationPerSegment = functions.animation.calculatedurationPerSegment(
					newTimeLine.length - 1
				)
				console.log(durationPerSegment)
				setValues({
					...values,
					canvas: {
						...values.canvas,
						timeLine: newTimeLine,
					},
					animation: {
						...values.animation,
						durationPerSegment,
					},
				})
			},
			addTimeSegment: () => {
				const durationPerSegment = functions.animation.calculatedurationPerSegment(
					values.canvas.timeLine.length
				)
				setValues({
					...values,
					canvas: {
						...values.canvas,
						timeLine: [
							...values.canvas.timeLine,
							values.canvas.timeLine[values.canvas.timeLine.length - 1],
						],
					},
					animation: {
						...values.animation,
						durationPerSegment,
					},
				})
				setCurrentTimeSegment(values.canvas.timeLine.length)
			},
			startLivePreview: () => {
				const {
					animation: { totalTimeAnimation, durationPerSegment, animationType },
					canvas: { timeLine, size },
				} = values
				generateLivePreview(
					timeLine,
					durationPerSegment,
					size,
					totalTimeAnimation,
					animationType,
					setLivePreview
				)
			},
		},
		shapeMenu: {
			open: (id, newTimeLine) => {
				console.log('open')
				const selected = values.canvas.timeLine[currentTimeSegment].find(
					(shape) => {
						return shape.id === id && shape
					}
				)
				setValues({
					...values,
					shapeMenu: {
						...values.shapeMenu,
						selected,
					},
					canvas: newTimeLine
						? { ...values.canvas, timeLine: newTimeLine }
						: { ...values.canvas },
				})
			},
			dragShape: (x, y, shapeId) => {
				let shapeToChange = {}
				const timeLine = values.canvas.timeLine
				const appel = timeLine[currentTimeSegment].map((shape) => {
					if (shape.id === shapeId) {
						// if (shape.shape === 'svg') {
						// 	shapeToChange = {
						// 		...shape,
						// 		transform: `translate(${Math.round(x) || 0}, ${
						// 			Math.round(y) || 0
						// 		})`,
						// 		x: Math.round(x),
						// 		y: Math.round(y),
						// 	}
						// } else {
						shapeToChange = {
							...shape,
							[shape.shape === 'circle' ? 'cx' : 'x']: Math.round(x),
							[shape.shape === 'circle' ? 'cy' : 'y']: Math.round(y),
						}
						// }
						return shapeToChange
					} else {
						return shape
					}
				})

				timeLine[currentTimeSegment] = appel

				setValues({
					...values,
					shapeMenu: {
						...values.shapeMenu,
						selected: shapeToChange,
					},
				})
			},
			updateShape: (e, shapeId) => {
				let { value, id, type } = e.target
				const valueToChange = id
				let shapeToChange = {}

				value = type === 'number' ? Number(value) : value

				value =
					id === 'opacity' || id === 'scale'
						? isNaN(value) || value === null
							? 0
							: value / 100
						: value

				const timeLine = values.canvas.timeLine

				const appel = timeLine[currentTimeSegment].map((shape) => {
					if (shape.id === shapeId) {
						shapeToChange = {
							...shape,
							[valueToChange]: value,
						}
						return {
							...shape,
							[valueToChange]: value,
						}
					} else {
						return shape
					}
				})

				timeLine[currentTimeSegment] = appel

				setValues({
					...values,
					canvas: {
						...values.canvas,
						timeLine: timeLine,
					},
					shapeMenu: {
						...values.shapeMenu,
						selected: shapeToChange,
					},
				})
			},
			copyShape: (shapeId) => {
				let copyOfShape = values.canvas.timeLine[currentTimeSegment].find(
					(shape) => shape.id === shapeId
				)
				const newId = uuidv4()

				let anotherCopyOfTimeLine = values.canvas.timeLine.map((segment, i) => {
					return [
						...segment,
						{
							...copyOfShape,
							id: newId,
							opacity: i === currentTimeSegment ? 1 : 0.1,
							x: copyOfShape.x ? copyOfShape.x + 10 : null,
							y: copyOfShape.y ? copyOfShape.y - 10 : null,
							cx: copyOfShape.cx ? copyOfShape.cx + 10 : null,
							cy: copyOfShape.cy ? copyOfShape.cy - 10 : null,
						},
					]
				})

				functions.shapeMenu.open(newId, anotherCopyOfTimeLine)
			},
			removeShape: (shapeId) => {
				let newTimeLine = []
				const timeLine = values.canvas.timeLine
				timeLine.forEach((segment) => {
					const filteredSegment = segment.filter((shape) => {
						if (shape.id !== shapeId) {
							return true
						} else {
							return false
						}
					})
					newTimeLine.push(filteredSegment)
				})
				setValues({
					...values,
					canvas: {
						...values.canvas,
						timeLine: newTimeLine,
					},
				})
			},
			shiftLayerUp: (up) => {
				const shapeId = values.shapeMenu.selected.id
				const copyOfTimeLine = values.canvas.timeLine
				const index = copyOfTimeLine[0].map((e) => e.id).indexOf(shapeId) // https://stackoverflow.com/questions/8668174/indexof-method-in-an-object-array
				if (up) {
					if (index === copyOfTimeLine[0].length - 1) {
						return
					} else {
						copyOfTimeLine.map((segment) => {
							return moveArrayPositions(segment, index, index + 1)
						})
						functions.canvas.updateTimeLine(copyOfTimeLine)
					}
				} else {
					if (index === 0) {
						return
					} else {
						copyOfTimeLine.map((segment) => {
							return moveArrayPositions(segment, index, index - 1)
						})
						functions.canvas.updateTimeLine(copyOfTimeLine)
					}
				}
			},
		},
	}

	useEffect(() => {
		if (values.shapeMenu.selected) {
			functions.shapeMenu.open(values.shapeMenu.selected.id)
		} else {
			return
		}
	}, [currentTimeSegment])

	if (values.isHomePage) {
		document.querySelector('body').style.overflow = 'auto'
	} else {
		document.querySelector('body').style.overflow = 'hidden'
		window.scrollTo(0, 0)
	}

	return (
		<Context.Provider value={{ values, functions }}>
			<Header values={values} setValues={setValues} />
			<>
				{values.isHomePage ? (
					<HomePage values={values} setValues={setValues} />
				) : (
					<>
						<GettingStartedModals />
						<ShapeMenu
							isOpen={values.shapeMenu.isOpen}
							selected={values.shapeMenu.selected}
							shiftLayerUp={functions.shapeMenu.shiftLayerUp}
						/>
						<Export
							totalTimeAnimation={values.animation.totalTimeAnimation}
							timeLine={values.canvas.timeLine}
							durationPerSegment={values.animation.durationPerSegment}
							canvasSize={values.canvas.size}
							animationType={values.animation.animationType}
							setCodeIsVisible={setCodeIsVisible}
							codeIsVisible={codeIsVisible}
						/>
						<Canvas
							timeLine={values.canvas.timeLine}
							currentTimeSegment={currentTimeSegment}
							canvasSize={values.canvas.size}
							livePreview={livePreview}
							canvasScale={canvasScale}
							setCanvasScale={setCanvasScale}
						/>
						<Tools currentTimeSegment={currentTimeSegment} />
						<TimeLine
							canvasSize={values.canvas.size}
							durationPerSegment={values.animation.durationPerSegment}
							timeLine={values.canvas.timeLine}
							currentTimeSegment={currentTimeSegment}
							setCurrentTimeSegment={setCurrentTimeSegment}
							totalTimeAnimation={values.animation.totalTimeAnimation}
							changeAnimationDuration={
								functions.animation.changeAnimationDuration
							}
							setAnimationType={functions.animation.setAnimationType}
							animationType={values.animation.animationType}
							livePreview={livePreview}
							setCodeIsVisible={setCodeIsVisible}
							codeIsVisible={codeIsVisible}
						/>
					</>
				)}
			</>
		</Context.Provider>
	)
}

export default App

// https://stackoverflow.com/questions/54150783/react-hooks-usestate-with-object
