import React, { useState } from 'react'
import styled from 'styled-components'
import { gettingStartedModals } from '../../../helpers/gettingStarted'

const ModalWrapper = styled.div`
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 5;
`

const StyledModal = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	width: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 2rem;
	border-radius: 10px;
`

const StyledCloseButton = styled.div`
	text-align: right;
	margin: 1rem 1rem 0 0;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
`

const GettingStartedModals = () => {
	const [currentModal, setCurrentModal] = useState(0)

	return currentModal < gettingStartedModals.length ? (
		<ModalWrapper
			id='wrapper'
			onClick={({ target: { id } }) => {
				id === 'wrapper' && setCurrentModal(gettingStartedModals.length)
			}}
		>
			<StyledModal>
				<StyledCloseButton
					onClick={() => {
						setCurrentModal(gettingStartedModals.length)
					}}
				>
					<p>x</p>
				</StyledCloseButton>
				<h1>{gettingStartedModals[currentModal].title}</h1>
				<p>{gettingStartedModals[currentModal].text}</p>
				<button
					onClick={() => {
						setCurrentModal(currentModal + 1)
					}}
				>
					{gettingStartedModals[currentModal].buttonText}
				</button>
			</StyledModal>
		</ModalWrapper>
	) : null
}

export default GettingStartedModals
