import { generateHTML } from './export'

export const generateLivePreview = (
	timeLine,
	durationPerSegment,
	canvasSize,
	totalTimeAnimation,
	animationType,
	setLivePreview,
	forCard = false
) => {
	const returnSvgOnly = true
	const svg = generateHTML(
		timeLine,
		durationPerSegment,
		canvasSize,
		totalTimeAnimation,
		animationType,
		returnSvgOnly
	)

	if (forCard) {
		return svg
	} else {
		setLivePreview(svg)

		console.log(svg)

		setTimeout(() => {
			setLivePreview(null)
		}, totalTimeAnimation * 2)
	}
}
