export const templates = [
	[
		[
			{
				id: 'bd756860-5211-4a7f-b798-5db3a77b7192',
				r: 143,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 150,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#9672fb',
			},
			{
				id: 'f57d8881-a6d7-44eb-a7b4-4f7fdb0a88d4',
				r: null,
				rx: 36,
				width: 100,
				height: 200,
				stroke: '#ffffff',
				strokeWidth: 10,
				opacity: 1,
				htmlName: 'rect',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 100,
				y: 50,
				shape: 'square',
				fill: '#9672fb',
			},
			{
				id: '9c6fe019-3462-43bf-b416-7ad77229ba16',
				r: 10,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 0,
				htmlName: 'circle',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 80,
				x: null,
				y: null,
				shape: 'circle',
				fill: '#ffffff',
			},
		],
		[
			{
				id: 'bd756860-5211-4a7f-b798-5db3a77b7192',
				r: 143,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 150,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#9672fb',
			},
			{
				id: 'f57d8881-a6d7-44eb-a7b4-4f7fdb0a88d4',
				r: null,
				rx: 36,
				width: 100,
				height: 200,
				stroke: '#ffffff',
				strokeWidth: 10,
				opacity: 1,
				htmlName: 'rect',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 100,
				y: 60,
				shape: 'square',
				fill: '#9672fb',
			},
			{
				id: '9c6fe019-3462-43bf-b416-7ad77229ba16',
				r: 10,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 110,
				x: null,
				y: null,
				shape: 'circle',
				fill: '#ffffff',
			},
		],
		[
			{
				id: 'bd756860-5211-4a7f-b798-5db3a77b7192',
				r: 143,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 150,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#9672fb',
			},
			{
				id: 'f57d8881-a6d7-44eb-a7b4-4f7fdb0a88d4',
				r: null,
				rx: 36,
				width: 100,
				height: 200,
				stroke: '#ffffff',
				strokeWidth: 10,
				opacity: 1,
				htmlName: 'rect',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 100,
				y: 50,
				shape: 'square',
				fill: '#9672fb',
			},
			{
				id: '9c6fe019-3462-43bf-b416-7ad77229ba16',
				r: 10,
				rx: null,
				width: null,
				height: null,
				stroke: '#ffffff',
				strokeWidth: 0,
				opacity: 0,
				htmlName: 'circle',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 140,
				x: null,
				y: null,
				shape: 'circle',
				fill: '#9672fb',
			},
		],
	],
	[
		[
			{
				id: '76cdfd49-573d-408f-9a67-f9f01f713e55',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#34a853',
			},
			{
				id: '2438684e-9157-4174-87d7-650deccfcde0',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#fbbc05',
			},
			{
				id: '3d54476b-41c2-4670-a011-0d472e8b1b38',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ea4335',
			},
			{
				id: '7e414446-aff1-4ded-950d-2a0c5a7f31b8',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#4285f4',
			},
		],
		[
			{
				id: '76cdfd49-573d-408f-9a67-f9f01f713e55',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#34a853',
			},
			{
				id: '2438684e-9157-4174-87d7-650deccfcde0',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#fbbc05',
			},
			{
				id: '3d54476b-41c2-4670-a011-0d472e8b1b38',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ea4335',
			},
			{
				id: '7e414446-aff1-4ded-950d-2a0c5a7f31b8',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#4285f4',
			},
		],
		[
			{
				id: '76cdfd49-573d-408f-9a67-f9f01f713e55',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#34a853',
			},
			{
				id: '2438684e-9157-4174-87d7-650deccfcde0',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#fbbc05',
			},
			{
				id: '3d54476b-41c2-4670-a011-0d472e8b1b38',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ea4335',
			},
			{
				id: '7e414446-aff1-4ded-950d-2a0c5a7f31b8',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#4285f4',
			},
		],
		[
			{
				id: '76cdfd49-573d-408f-9a67-f9f01f713e55',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#34a853',
			},
			{
				id: '2438684e-9157-4174-87d7-650deccfcde0',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#fbbc05',
			},
			{
				id: '3d54476b-41c2-4670-a011-0d472e8b1b38',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ea4335',
			},
			{
				id: '7e414446-aff1-4ded-950d-2a0c5a7f31b8',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#4285f4',
			},
		],
		[
			{
				id: '76cdfd49-573d-408f-9a67-f9f01f713e55',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#34a853',
			},
			{
				id: '2438684e-9157-4174-87d7-650deccfcde0',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 200,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#fbbc05',
			},
			{
				id: '3d54476b-41c2-4670-a011-0d472e8b1b38',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 200,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ea4335',
			},
			{
				id: '7e414446-aff1-4ded-950d-2a0c5a7f31b8',
				r: 40,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 100,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#4285f4',
			},
		],
	],
	[
		[
			{
				id: '0c349b7e-4cea-4ca6-ac68-d14e01f0ab95',
				r: 7,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: 'black',
			},
			{
				id: '895540dd-0ed8-4e9e-a17e-ce750ddcf944',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 62,
				y: 62,
				scale: 1,
				shape: 'svg',
				content:
					'\n    <g fill-rule="evenodd" clip-rule="evenodd">\n        <path\n            d="M34.175 107.36c-1.488 0-2.653 1.041-2.653 2.56 0 1.52 1.171 2.555 2.653 2.555 1.481 0 2.653-1.042 2.653-2.555 0-1.519-1.166-2.56-2.653-2.56zm0 4.383c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.847.793 1.847 1.828 0 1.029-.8 1.823-1.847 1.823zM59.99 98.406H47.075l1.468-22.448-8.387 22.448H28.163l19.029-50.739h12.761L58.48 70.423l8.546-22.756H79.02L59.99 98.406zM61.548 98.406l19.028-50.739h11.993L73.541 98.406H61.548z" />\n        <path\n            d="M26.78 80.917c-16.759 19.681-32.568 45.868-18.604 57.927 13.531 11.686 30.444 4.767 41.976.154s138.995-59.965 138.995-59.965c1.23-.615 1-1.384-.538-1-.62.155-138.764 37.593-138.764 37.593-17.53 4.921-37.518-5.804-23.065-34.709z" />\n        <path\n            d="M34.627 110.1c.372-.018.663-.21.663-.725 0-.664-.434-.831-1.159-.831h-1.042v2.753h.695v-1.172h.117l.633 1.172h.799l-.706-1.197zm-.446-.446h-.396v-.639h.334c.205 0 .44.024.44.291 0 .286-.136.348-.378.348zM154.733 47.512c-1.488 0-2.653 1.042-2.653 2.56 0 1.519 1.172 2.554 2.653 2.554s2.653-1.042 2.653-2.554c.001-1.518-1.164-2.56-2.653-2.56zm0 4.384c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.848.793 1.848 1.828 0 1.029-.8 1.823-1.848 1.823z" />\n        <path\n            d="M155.186 50.252c.372-.018.664-.21.664-.725 0-.664-.435-.831-1.159-.831h-1.042v2.752h.694v-1.171h.118l.632 1.171h.8l-.707-1.196zm-.447-.446h-.396v-.639h.335c.204 0 .44.025.44.292 0 .285-.137.347-.379.347zM146.831 56.124l3.223-8.457H113.46l-12.565 15.222 5.801-15.222H94.549L75.673 98.406h11.993l8.059-21.218.552 21.218 36.799-.08 3.503-9.76h-13.991l4.597-12.3h11.531l3.548-9.456h-11.531l3.953-10.686h12.145zm-38.656 38.899l-.769-26.445 18.133-19.909-17.364 46.354z" />\n    </g>\n',
				fill: 'black',
			},
		],
		[
			{
				id: '0c349b7e-4cea-4ca6-ac68-d14e01f0ab95',
				r: 250,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ff0000',
			},
			{
				id: '895540dd-0ed8-4e9e-a17e-ce750ddcf944',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 63,
				y: 66,
				scale: 1,
				shape: 'svg',
				content:
					'\n    <g fill-rule="evenodd" clip-rule="evenodd">\n        <path\n            d="M34.175 107.36c-1.488 0-2.653 1.041-2.653 2.56 0 1.52 1.171 2.555 2.653 2.555 1.481 0 2.653-1.042 2.653-2.555 0-1.519-1.166-2.56-2.653-2.56zm0 4.383c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.847.793 1.847 1.828 0 1.029-.8 1.823-1.847 1.823zM59.99 98.406H47.075l1.468-22.448-8.387 22.448H28.163l19.029-50.739h12.761L58.48 70.423l8.546-22.756H79.02L59.99 98.406zM61.548 98.406l19.028-50.739h11.993L73.541 98.406H61.548z" />\n        <path\n            d="M26.78 80.917c-16.759 19.681-32.568 45.868-18.604 57.927 13.531 11.686 30.444 4.767 41.976.154s138.995-59.965 138.995-59.965c1.23-.615 1-1.384-.538-1-.62.155-138.764 37.593-138.764 37.593-17.53 4.921-37.518-5.804-23.065-34.709z" />\n        <path\n            d="M34.627 110.1c.372-.018.663-.21.663-.725 0-.664-.434-.831-1.159-.831h-1.042v2.753h.695v-1.172h.117l.633 1.172h.799l-.706-1.197zm-.446-.446h-.396v-.639h.334c.205 0 .44.024.44.291 0 .286-.136.348-.378.348zM154.733 47.512c-1.488 0-2.653 1.042-2.653 2.56 0 1.519 1.172 2.554 2.653 2.554s2.653-1.042 2.653-2.554c.001-1.518-1.164-2.56-2.653-2.56zm0 4.384c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.848.793 1.848 1.828 0 1.029-.8 1.823-1.848 1.823z" />\n        <path\n            d="M155.186 50.252c.372-.018.664-.21.664-.725 0-.664-.435-.831-1.159-.831h-1.042v2.752h.694v-1.171h.118l.632 1.171h.8l-.707-1.196zm-.447-.446h-.396v-.639h.335c.204 0 .44.025.44.292 0 .285-.137.347-.379.347zM146.831 56.124l3.223-8.457H113.46l-12.565 15.222 5.801-15.222H94.549L75.673 98.406h11.993l8.059-21.218.552 21.218 36.799-.08 3.503-9.76h-13.991l4.597-12.3h11.531l3.548-9.456h-11.531l3.953-10.686h12.145zm-38.656 38.899l-.769-26.445 18.133-19.909-17.364 46.354z" />\n    </g>\n',
				fill: '#ffffff',
			},
		],
		[
			{
				id: '0c349b7e-4cea-4ca6-ac68-d14e01f0ab95',
				r: 250,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ff0000',
			},
			{
				id: '895540dd-0ed8-4e9e-a17e-ce750ddcf944',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 63,
				y: 66,
				scale: 1,
				shape: 'svg',
				content:
					'\n    <g fill-rule="evenodd" clip-rule="evenodd">\n        <path\n            d="M34.175 107.36c-1.488 0-2.653 1.041-2.653 2.56 0 1.52 1.171 2.555 2.653 2.555 1.481 0 2.653-1.042 2.653-2.555 0-1.519-1.166-2.56-2.653-2.56zm0 4.383c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.847.793 1.847 1.828 0 1.029-.8 1.823-1.847 1.823zM59.99 98.406H47.075l1.468-22.448-8.387 22.448H28.163l19.029-50.739h12.761L58.48 70.423l8.546-22.756H79.02L59.99 98.406zM61.548 98.406l19.028-50.739h11.993L73.541 98.406H61.548z" />\n        <path\n            d="M26.78 80.917c-16.759 19.681-32.568 45.868-18.604 57.927 13.531 11.686 30.444 4.767 41.976.154s138.995-59.965 138.995-59.965c1.23-.615 1-1.384-.538-1-.62.155-138.764 37.593-138.764 37.593-17.53 4.921-37.518-5.804-23.065-34.709z" />\n        <path\n            d="M34.627 110.1c.372-.018.663-.21.663-.725 0-.664-.434-.831-1.159-.831h-1.042v2.753h.695v-1.172h.117l.633 1.172h.799l-.706-1.197zm-.446-.446h-.396v-.639h.334c.205 0 .44.024.44.291 0 .286-.136.348-.378.348zM154.733 47.512c-1.488 0-2.653 1.042-2.653 2.56 0 1.519 1.172 2.554 2.653 2.554s2.653-1.042 2.653-2.554c.001-1.518-1.164-2.56-2.653-2.56zm0 4.384c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.848.793 1.848 1.828 0 1.029-.8 1.823-1.848 1.823z" />\n        <path\n            d="M155.186 50.252c.372-.018.664-.21.664-.725 0-.664-.435-.831-1.159-.831h-1.042v2.752h.694v-1.171h.118l.632 1.171h.8l-.707-1.196zm-.447-.446h-.396v-.639h.335c.204 0 .44.025.44.292 0 .285-.137.347-.379.347zM146.831 56.124l3.223-8.457H113.46l-12.565 15.222 5.801-15.222H94.549L75.673 98.406h11.993l8.059-21.218.552 21.218 36.799-.08 3.503-9.76h-13.991l4.597-12.3h11.531l3.548-9.456h-11.531l3.953-10.686h12.145zm-38.656 38.899l-.769-26.445 18.133-19.909-17.364 46.354z" />\n    </g>\n',
				fill: '#ffffff',
			},
		],
		[
			{
				id: '0c349b7e-4cea-4ca6-ac68-d14e01f0ab95',
				r: 7,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: 'black',
			},
			{
				id: '895540dd-0ed8-4e9e-a17e-ce750ddcf944',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 62,
				y: 62,
				scale: 1,
				shape: 'svg',
				content:
					'\n    <g fill-rule="evenodd" clip-rule="evenodd">\n        <path\n            d="M34.175 107.36c-1.488 0-2.653 1.041-2.653 2.56 0 1.52 1.171 2.555 2.653 2.555 1.481 0 2.653-1.042 2.653-2.555 0-1.519-1.166-2.56-2.653-2.56zm0 4.383c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.847.793 1.847 1.828 0 1.029-.8 1.823-1.847 1.823zM59.99 98.406H47.075l1.468-22.448-8.387 22.448H28.163l19.029-50.739h12.761L58.48 70.423l8.546-22.756H79.02L59.99 98.406zM61.548 98.406l19.028-50.739h11.993L73.541 98.406H61.548z" />\n        <path\n            d="M26.78 80.917c-16.759 19.681-32.568 45.868-18.604 57.927 13.531 11.686 30.444 4.767 41.976.154s138.995-59.965 138.995-59.965c1.23-.615 1-1.384-.538-1-.62.155-138.764 37.593-138.764 37.593-17.53 4.921-37.518-5.804-23.065-34.709z" />\n        <path\n            d="M34.627 110.1c.372-.018.663-.21.663-.725 0-.664-.434-.831-1.159-.831h-1.042v2.753h.695v-1.172h.117l.633 1.172h.799l-.706-1.197zm-.446-.446h-.396v-.639h.334c.205 0 .44.024.44.291 0 .286-.136.348-.378.348zM154.733 47.512c-1.488 0-2.653 1.042-2.653 2.56 0 1.519 1.172 2.554 2.653 2.554s2.653-1.042 2.653-2.554c.001-1.518-1.164-2.56-2.653-2.56zm0 4.384c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.848.793 1.848 1.828 0 1.029-.8 1.823-1.848 1.823z" />\n        <path\n            d="M155.186 50.252c.372-.018.664-.21.664-.725 0-.664-.435-.831-1.159-.831h-1.042v2.752h.694v-1.171h.118l.632 1.171h.8l-.707-1.196zm-.447-.446h-.396v-.639h.335c.204 0 .44.025.44.292 0 .285-.137.347-.379.347zM146.831 56.124l3.223-8.457H113.46l-12.565 15.222 5.801-15.222H94.549L75.673 98.406h11.993l8.059-21.218.552 21.218 36.799-.08 3.503-9.76h-13.991l4.597-12.3h11.531l3.548-9.456h-11.531l3.953-10.686h12.145zm-38.656 38.899l-.769-26.445 18.133-19.909-17.364 46.354z" />\n    </g>\n',
				fill: 'black',
			},
		],
		[
			{
				id: '0c349b7e-4cea-4ca6-ac68-d14e01f0ab95',
				r: 7,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 150,
				cy: 100,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: 'black',
			},
			{
				id: '895540dd-0ed8-4e9e-a17e-ce750ddcf944',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 62,
				y: 62,
				scale: 1,
				shape: 'svg',
				content:
					'\n    <g fill-rule="evenodd" clip-rule="evenodd">\n        <path\n            d="M34.175 107.36c-1.488 0-2.653 1.041-2.653 2.56 0 1.52 1.171 2.555 2.653 2.555 1.481 0 2.653-1.042 2.653-2.555 0-1.519-1.166-2.56-2.653-2.56zm0 4.383c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.847.793 1.847 1.828 0 1.029-.8 1.823-1.847 1.823zM59.99 98.406H47.075l1.468-22.448-8.387 22.448H28.163l19.029-50.739h12.761L58.48 70.423l8.546-22.756H79.02L59.99 98.406zM61.548 98.406l19.028-50.739h11.993L73.541 98.406H61.548z" />\n        <path\n            d="M26.78 80.917c-16.759 19.681-32.568 45.868-18.604 57.927 13.531 11.686 30.444 4.767 41.976.154s138.995-59.965 138.995-59.965c1.23-.615 1-1.384-.538-1-.62.155-138.764 37.593-138.764 37.593-17.53 4.921-37.518-5.804-23.065-34.709z" />\n        <path\n            d="M34.627 110.1c.372-.018.663-.21.663-.725 0-.664-.434-.831-1.159-.831h-1.042v2.753h.695v-1.172h.117l.633 1.172h.799l-.706-1.197zm-.446-.446h-.396v-.639h.334c.205 0 .44.024.44.291 0 .286-.136.348-.378.348zM154.733 47.512c-1.488 0-2.653 1.042-2.653 2.56 0 1.519 1.172 2.554 2.653 2.554s2.653-1.042 2.653-2.554c.001-1.518-1.164-2.56-2.653-2.56zm0 4.384c-1.061 0-1.848-.775-1.848-1.823 0-1.047.781-1.828 1.848-1.828 1.054 0 1.848.793 1.848 1.828 0 1.029-.8 1.823-1.848 1.823z" />\n        <path\n            d="M155.186 50.252c.372-.018.664-.21.664-.725 0-.664-.435-.831-1.159-.831h-1.042v2.752h.694v-1.171h.118l.632 1.171h.8l-.707-1.196zm-.447-.446h-.396v-.639h.335c.204 0 .44.025.44.292 0 .285-.137.347-.379.347zM146.831 56.124l3.223-8.457H113.46l-12.565 15.222 5.801-15.222H94.549L75.673 98.406h11.993l8.059-21.218.552 21.218 36.799-.08 3.503-9.76h-13.991l4.597-12.3h11.531l3.548-9.456h-11.531l3.953-10.686h12.145zm-38.656 38.899l-.769-26.445 18.133-19.909-17.364 46.354z" />\n    </g>\n',
				fill: 'black',
			},
		],
	],
	[
		[
			{
				id: '623a35c2-4829-44d1-881c-b5436d4b57a4',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 116,
				y: 205,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><path d="M208.132,267.142a22.6,22.6,0,1,1,22.543,22.617A22.639,22.639,0,0,1,208.132,267.142Zm42.55.043A19.954,19.954,0,1,0,230.7,287.118,20.01,20.01,0,0,0,250.682,267.185Z" transform="translate(382.868 692.231)"/></g>',
				fill: '#9672fb',
			},
			{
				id: 'fabb3188-b95b-496b-8900-965626d27ea7',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 117,
				y: 204,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><g transform="translate(593.732 942.749)"><path d="M122.349,275.689h18.4c.149,0,.3,0,.447,0a1.128,1.128,0,0,1,1.185,1.219q0,14.774,0,29.548a1.141,1.141,0,0,1-1.051,1.22,3.371,3.371,0,0,1-.533.035q-18.418,0-36.838,0a3.408,3.408,0,0,1-.489-.028,1.155,1.155,0,0,1-1.1-1.27q0-14.729,0-29.459c0-.855.46-1.268,1.406-1.268Q113.062,275.687,122.349,275.689Zm-17.612,2.358v27.267h35.278V278.047Z" transform="translate(-102.366 -275.688)"/></g></g>',
				fill: 'black',
			},
			{
				id: 'e4704331-e3aa-45ec-bd9a-3e8f1de77021',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 117,
				y: 203,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><g transform="translate(445.22 573.983)"><path d="M169.115,400.695q-8.678,0-17.357,0a3.663,3.663,0,0,1-3.748-2.452,3.61,3.61,0,0,1,.394-3.275q1.952-3.217,3.888-6.443,6.766-11.215,13.529-22.433a3.7,3.7,0,0,1,3.561-2.018,3.743,3.743,0,0,1,3.089,2.023q5.97,9.888,11.944,19.774,2.746,4.545,5.49,9.093a3.673,3.673,0,0,1-.99,5.1,3.362,3.362,0,0,1-1.919.623q-3.457.017-6.914.013Q174.6,400.7,169.115,400.695Zm.046-2.5h17.266a4.288,4.288,0,0,0,.524-.014,1.112,1.112,0,0,0,1.043-1.109,2.11,2.11,0,0,0-.335-.965q-4.641-7.715-9.3-15.418-4.015-6.646-8.04-13.287a2,2,0,0,0-.761-.742c-.616-.3-1.119-.036-1.58.73q-6.966,11.544-13.928,23.089-1.735,2.876-3.469,5.752c-.374.622-.383,1.24.09,1.6a2.011,2.011,0,0,0,1.135.354C157.588,398.206,163.374,398.2,169.161,398.2Z" transform="translate(0)"/></g><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/></g>',
				fill: 'black',
			},
			{
				id: '42e3e0ba-8986-461d-b71b-7259da156e58',
				r: 45,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 151,
				cy: 235,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ffffff',
			},
			{
				id: '15aa7e17-bd2d-49e3-8168-1fc8ac929caf',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 99,
				y: 188,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z" transform="translate(370.868 679.42)"/><g transform="translate(498.634 676.312)"><path d="M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z"/></g></g>',
				fill: '#9672fb',
			},
		],
		[
			{
				id: '623a35c2-4829-44d1-881c-b5436d4b57a4',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 48,
				y: 128,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><path d="M208.132,267.142a22.6,22.6,0,1,1,22.543,22.617A22.639,22.639,0,0,1,208.132,267.142Zm42.55.043A19.954,19.954,0,1,0,230.7,287.118,20.01,20.01,0,0,0,250.682,267.185Z" transform="translate(382.868 692.231)"/></g>',
				fill: '#d772fb',
			},
			{
				id: 'fabb3188-b95b-496b-8900-965626d27ea7',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 117,
				y: 204,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><g transform="translate(593.732 942.749)"><path d="M122.349,275.689h18.4c.149,0,.3,0,.447,0a1.128,1.128,0,0,1,1.185,1.219q0,14.774,0,29.548a1.141,1.141,0,0,1-1.051,1.22,3.371,3.371,0,0,1-.533.035q-18.418,0-36.838,0a3.408,3.408,0,0,1-.489-.028,1.155,1.155,0,0,1-1.1-1.27q0-14.729,0-29.459c0-.855.46-1.268,1.406-1.268Q113.062,275.687,122.349,275.689Zm-17.612,2.358v27.267h35.278V278.047Z" transform="translate(-102.366 -275.688)"/></g></g>',
				fill: '#9672fb',
			},
			{
				id: 'e4704331-e3aa-45ec-bd9a-3e8f1de77021',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 115,
				y: 204,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><g transform="translate(445.22 573.983)"><path d="M169.115,400.695q-8.678,0-17.357,0a3.663,3.663,0,0,1-3.748-2.452,3.61,3.61,0,0,1,.394-3.275q1.952-3.217,3.888-6.443,6.766-11.215,13.529-22.433a3.7,3.7,0,0,1,3.561-2.018,3.743,3.743,0,0,1,3.089,2.023q5.97,9.888,11.944,19.774,2.746,4.545,5.49,9.093a3.673,3.673,0,0,1-.99,5.1,3.362,3.362,0,0,1-1.919.623q-3.457.017-6.914.013Q174.6,400.7,169.115,400.695Zm.046-2.5h17.266a4.288,4.288,0,0,0,.524-.014,1.112,1.112,0,0,0,1.043-1.109,2.11,2.11,0,0,0-.335-.965q-4.641-7.715-9.3-15.418-4.015-6.646-8.04-13.287a2,2,0,0,0-.761-.742c-.616-.3-1.119-.036-1.58.73q-6.966,11.544-13.928,23.089-1.735,2.876-3.469,5.752c-.374.622-.383,1.24.09,1.6a2.011,2.011,0,0,0,1.135.354C157.588,398.206,163.374,398.2,169.161,398.2Z" transform="translate(0)"/></g><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/></g>',
				fill: 'black',
			},
			{
				id: '42e3e0ba-8986-461d-b71b-7259da156e58',
				r: 46,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 149,
				cy: 235,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ffffff',
			},
			{
				id: '15aa7e17-bd2d-49e3-8168-1fc8ac929caf',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 98,
				y: 187,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z" transform="translate(370.868 679.42)"/><g transform="translate(498.634 676.312)"><path d="M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z"/></g></g>',
				fill: '#9672fb',
			},
		],
		[
			{
				id: '623a35c2-4829-44d1-881c-b5436d4b57a4',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 42,
				y: 131,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><path d="M208.132,267.142a22.6,22.6,0,1,1,22.543,22.617A22.639,22.639,0,0,1,208.132,267.142Zm42.55.043A19.954,19.954,0,1,0,230.7,287.118,20.01,20.01,0,0,0,250.682,267.185Z" transform="translate(382.868 692.231)"/></g>',
				fill: '#d772fb',
			},
			{
				id: 'fabb3188-b95b-496b-8900-965626d27ea7',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 114,
				y: 103,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><g transform="translate(593.732 942.749)"><path d="M122.349,275.689h18.4c.149,0,.3,0,.447,0a1.128,1.128,0,0,1,1.185,1.219q0,14.774,0,29.548a1.141,1.141,0,0,1-1.051,1.22,3.371,3.371,0,0,1-.533.035q-18.418,0-36.838,0a3.408,3.408,0,0,1-.489-.028,1.155,1.155,0,0,1-1.1-1.27q0-14.729,0-29.459c0-.855.46-1.268,1.406-1.268Q113.062,275.687,122.349,275.689Zm-17.612,2.358v27.267h35.278V278.047Z" transform="translate(-102.366 -275.688)"/></g></g>',
				fill: '#d772fb',
			},
			{
				id: 'e4704331-e3aa-45ec-bd9a-3e8f1de77021',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 115,
				y: 204,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><g transform="translate(445.22 573.983)"><path d="M169.115,400.695q-8.678,0-17.357,0a3.663,3.663,0,0,1-3.748-2.452,3.61,3.61,0,0,1,.394-3.275q1.952-3.217,3.888-6.443,6.766-11.215,13.529-22.433a3.7,3.7,0,0,1,3.561-2.018,3.743,3.743,0,0,1,3.089,2.023q5.97,9.888,11.944,19.774,2.746,4.545,5.49,9.093a3.673,3.673,0,0,1-.99,5.1,3.362,3.362,0,0,1-1.919.623q-3.457.017-6.914.013Q174.6,400.7,169.115,400.695Zm.046-2.5h17.266a4.288,4.288,0,0,0,.524-.014,1.112,1.112,0,0,0,1.043-1.109,2.11,2.11,0,0,0-.335-.965q-4.641-7.715-9.3-15.418-4.015-6.646-8.04-13.287a2,2,0,0,0-.761-.742c-.616-.3-1.119-.036-1.58.73q-6.966,11.544-13.928,23.089-1.735,2.876-3.469,5.752c-.374.622-.383,1.24.09,1.6a2.011,2.011,0,0,0,1.135.354C157.588,398.206,163.374,398.2,169.161,398.2Z" transform="translate(0)"/></g><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/></g>',
				fill: '#9672fb',
			},
			{
				id: '42e3e0ba-8986-461d-b71b-7259da156e58',
				r: 46,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 152,
				cy: 237,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ffffff',
			},
			{
				id: '15aa7e17-bd2d-49e3-8168-1fc8ac929caf',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 99,
				y: 188,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z" transform="translate(370.868 679.42)"/><g transform="translate(498.634 676.312)"><path d="M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z"/></g></g>',
				fill: '#9672fb',
			},
		],
		[
			{
				id: '623a35c2-4829-44d1-881c-b5436d4b57a4',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 42,
				y: 131,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><path d="M208.132,267.142a22.6,22.6,0,1,1,22.543,22.617A22.639,22.639,0,0,1,208.132,267.142Zm42.55.043A19.954,19.954,0,1,0,230.7,287.118,20.01,20.01,0,0,0,250.682,267.185Z" transform="translate(382.868 692.231)"/></g>',
				fill: '#d772fb',
			},
			{
				id: 'fabb3188-b95b-496b-8900-965626d27ea7',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 113,
				y: 101,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/><g transform="translate(593.732 942.749)"><path d="M122.349,275.689h18.4c.149,0,.3,0,.447,0a1.128,1.128,0,0,1,1.185,1.219q0,14.774,0,29.548a1.141,1.141,0,0,1-1.051,1.22,3.371,3.371,0,0,1-.533.035q-18.418,0-36.838,0a3.408,3.408,0,0,1-.489-.028,1.155,1.155,0,0,1-1.1-1.27q0-14.729,0-29.459c0-.855.46-1.268,1.406-1.268Q113.062,275.687,122.349,275.689Zm-17.612,2.358v27.267h35.278V278.047Z" transform="translate(-102.366 -275.688)"/></g></g>',
				fill: '#d772fb',
			},
			{
				id: 'e4704331-e3aa-45ec-bd9a-3e8f1de77021',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 187,
				y: 134,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><g transform="translate(445.22 573.983)"><path d="M169.115,400.695q-8.678,0-17.357,0a3.663,3.663,0,0,1-3.748-2.452,3.61,3.61,0,0,1,.394-3.275q1.952-3.217,3.888-6.443,6.766-11.215,13.529-22.433a3.7,3.7,0,0,1,3.561-2.018,3.743,3.743,0,0,1,3.089,2.023q5.97,9.888,11.944,19.774,2.746,4.545,5.49,9.093a3.673,3.673,0,0,1-.99,5.1,3.362,3.362,0,0,1-1.919.623q-3.457.017-6.914.013Q174.6,400.7,169.115,400.695Zm.046-2.5h17.266a4.288,4.288,0,0,0,.524-.014,1.112,1.112,0,0,0,1.043-1.109,2.11,2.11,0,0,0-.335-.965q-4.641-7.715-9.3-15.418-4.015-6.646-8.04-13.287a2,2,0,0,0-.761-.742c-.616-.3-1.119-.036-1.58.73q-6.966,11.544-13.928,23.089-1.735,2.876-3.469,5.752c-.374.622-.383,1.24.09,1.6a2.011,2.011,0,0,0,1.135.354C157.588,398.206,163.374,398.2,169.161,398.2Z" transform="translate(0)"/></g><path d="M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z" transform="translate(370.868 679.42)"/></g>',
				fill: '#d772fb',
			},
			{
				id: '42e3e0ba-8986-461d-b71b-7259da156e58',
				r: 44,
				rx: null,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'circle',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: 151,
				cy: 235,
				x: null,
				y: null,
				scale: null,
				shape: 'circle',
				fill: '#ffffff',
			},
			{
				id: '15aa7e17-bd2d-49e3-8168-1fc8ac929caf',
				r: null,
				rx: 0,
				width: null,
				height: null,
				stroke: '#dddddd',
				strokeWidth: 0,
				opacity: 1,
				htmlName: 'g',
				transform: 'translate(0, 0)',
				transformOrigin: '50% 50%',
				cx: null,
				cy: null,
				x: 99,
				y: 188,
				scale: 1,
				shape: 'svg',
				content:
					'<g transform="translate(-579 -924)"><path d="M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z" transform="translate(370.868 679.42)"/><g transform="translate(498.634 676.312)"><path d="M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z"/></g></g>',
				fill: '#9672fb',
			},
		],
	],
	// [
	// 	[
	// 		{
	// 			id: 'e0af5796-addc-475e-b3e0-5fe778f3735d',
	// 			r: 55,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 151,
	// 			cy: 152,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '9da47bf5-485a-4e6a-a306-8b14b0c7a1ec',
	// 			r: 25,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 153,
	// 			cy: 164,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#d86e6e',
	// 		},
	// 		{
	// 			id: '7ceb7061-477e-41fc-9942-9ac61a040d7e',
	// 			r: null,
	// 			rx: 0,
	// 			width: 0,
	// 			height: 50,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 0.1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 125,
	// 			y: 109,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: 'black',
	// 		},
	// 		{
	// 			id: '5b7a90a9-67fe-4dc7-a097-9ee3fd487b78',
	// 			r: 29,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 152,
	// 			cy: 154,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '7fd546be-107b-4c9d-85a2-2b4c04577910',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#cb8b8b',
	// 		},
	// 		{
	// 			id: 'e1092708-e34f-45e1-a48d-88d1fb0def7e',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '7b12912f-4798-4c3d-8563-ff9e4fa62ca2',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#bd7f7f',
	// 		},
	// 		{
	// 			id: '6ed958aa-15d7-45a0-a3d2-9c440de3fc8c',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '991d2a9d-8d28-446f-937a-4e1a19245a90',
	// 			r: null,
	// 			rx: 3,
	// 			width: 10,
	// 			height: 10,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 145,
	// 			y: 153,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: '#d39797',
	// 		},
	// 		{
	// 			id: 'a76cdd65-defc-4fc4-b3ee-6d2673d90d63',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 169,
	// 			cy: 132,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: 'a9a9f87a-b118-42b1-955e-30cc125391fb',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 135,
	// 			cy: 133,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: '5c558d9d-e622-41f4-a104-273d222c0542',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 102,
	// 			cy: 114,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'f4445728-0fb6-4f10-adf1-bd7b0566a2ab',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 115,
	// 			cy: 106,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '8481c28e-6e50-4d13-9876-bc0dc471090d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 127,
	// 			cy: 94,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '53c1eb6e-a2d0-4cdb-809c-ecf7c8cf291a',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 142,
	// 			cy: 94,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '420d8774-4e25-48d4-86ab-532a53d2bb5d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 157,
	// 			cy: 99,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'c41d81c3-05ce-446a-8e73-0cb97c205474',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 171,
	// 			cy: 100,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '759cc049-50f2-4be6-a598-2d7079358329',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 183,
	// 			cy: 108,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '0d5e8d7d-ed09-4a6b-8b4d-3d4bd82bd226',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 193,
	// 			cy: 120,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '2591f718-3616-4b1f-9764-ca69f4453a43',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 99,
	// 			cy: 127,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 	],
	// 	[
	// 		{
	// 			id: 'e0af5796-addc-475e-b3e0-5fe778f3735d',
	// 			r: 55,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 151,
	// 			cy: 152,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '9da47bf5-485a-4e6a-a306-8b14b0c7a1ec',
	// 			r: 25,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 152,
	// 			cy: 177,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#d86e6e',
	// 		},
	// 		{
	// 			id: '7ceb7061-477e-41fc-9942-9ac61a040d7e',
	// 			r: null,
	// 			rx: 7,
	// 			width: 15,
	// 			height: 15,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 144,
	// 			y: 173,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '5b7a90a9-67fe-4dc7-a097-9ee3fd487b78',
	// 			r: 29,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 152,
	// 			cy: 154,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '7fd546be-107b-4c9d-85a2-2b4c04577910',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#cb8b8b',
	// 		},
	// 		{
	// 			id: 'e1092708-e34f-45e1-a48d-88d1fb0def7e',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '7b12912f-4798-4c3d-8563-ff9e4fa62ca2',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#bd7f7f',
	// 		},
	// 		{
	// 			id: '6ed958aa-15d7-45a0-a3d2-9c440de3fc8c',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '991d2a9d-8d28-446f-937a-4e1a19245a90',
	// 			r: null,
	// 			rx: 3,
	// 			width: 24,
	// 			height: 10,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 145,
	// 			y: 153,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: '#d39797',
	// 		},
	// 		{
	// 			id: 'a76cdd65-defc-4fc4-b3ee-6d2673d90d63',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 169,
	// 			cy: 125,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: 'a9a9f87a-b118-42b1-955e-30cc125391fb',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 125,
	// 			cy: 133,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: '5c558d9d-e622-41f4-a104-273d222c0542',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 100,
	// 			cy: 106,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'f4445728-0fb6-4f10-adf1-bd7b0566a2ab',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 115,
	// 			cy: 106,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '8481c28e-6e50-4d13-9876-bc0dc471090d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 121,
	// 			cy: 98,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '53c1eb6e-a2d0-4cdb-809c-ecf7c8cf291a',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 135,
	// 			cy: 91,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '420d8774-4e25-48d4-86ab-532a53d2bb5d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 151,
	// 			cy: 89,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'c41d81c3-05ce-446a-8e73-0cb97c205474',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 162,
	// 			cy: 96,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '759cc049-50f2-4be6-a598-2d7079358329',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 179,
	// 			cy: 102,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '0d5e8d7d-ed09-4a6b-8b4d-3d4bd82bd226',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 193,
	// 			cy: 116,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '2591f718-3616-4b1f-9764-ca69f4453a43',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 102,
	// 			cy: 123,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 	],
	// 	[
	// 		{
	// 			id: 'e0af5796-addc-475e-b3e0-5fe778f3735d',
	// 			r: 55,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 151,
	// 			cy: 152,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '9da47bf5-485a-4e6a-a306-8b14b0c7a1ec',
	// 			r: 25,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 153,
	// 			cy: 164,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#d86e6e',
	// 		},
	// 		{
	// 			id: '7ceb7061-477e-41fc-9942-9ac61a040d7e',
	// 			r: null,
	// 			rx: 0,
	// 			width: 50,
	// 			height: 0,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 0.1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 125,
	// 			y: 125,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: 'black',
	// 		},
	// 		{
	// 			id: '5b7a90a9-67fe-4dc7-a097-9ee3fd487b78',
	// 			r: 29,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 152,
	// 			cy: 154,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ecacac',
	// 		},
	// 		{
	// 			id: '7fd546be-107b-4c9d-85a2-2b4c04577910',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#cb8b8b',
	// 		},
	// 		{
	// 			id: 'e1092708-e34f-45e1-a48d-88d1fb0def7e',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 132,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '7b12912f-4798-4c3d-8563-ff9e4fa62ca2',
	// 			r: 13,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#bd7f7f',
	// 		},
	// 		{
	// 			id: '6ed958aa-15d7-45a0-a3d2-9c440de3fc8c',
	// 			r: 9,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 166,
	// 			cy: 131,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#ffffff',
	// 		},
	// 		{
	// 			id: '991d2a9d-8d28-446f-937a-4e1a19245a90',
	// 			r: null,
	// 			rx: 3,
	// 			width: 10,
	// 			height: 10,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'rect',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: null,
	// 			cy: null,
	// 			x: 145,
	// 			y: 153,
	// 			scale: null,
	// 			shape: 'square',
	// 			fill: '#d39797',
	// 		},
	// 		{
	// 			id: 'a76cdd65-defc-4fc4-b3ee-6d2673d90d63',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 169,
	// 			cy: 132,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: 'a9a9f87a-b118-42b1-955e-30cc125391fb',
	// 			r: 3,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#6b6b6b',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 135,
	// 			cy: 133,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#4f4f4f',
	// 		},
	// 		{
	// 			id: '5c558d9d-e622-41f4-a104-273d222c0542',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 102,
	// 			cy: 114,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'f4445728-0fb6-4f10-adf1-bd7b0566a2ab',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 115,
	// 			cy: 106,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '8481c28e-6e50-4d13-9876-bc0dc471090d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 127,
	// 			cy: 94,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '53c1eb6e-a2d0-4cdb-809c-ecf7c8cf291a',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 142,
	// 			cy: 94,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '420d8774-4e25-48d4-86ab-532a53d2bb5d',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 157,
	// 			cy: 99,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: 'c41d81c3-05ce-446a-8e73-0cb97c205474',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 171,
	// 			cy: 100,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '759cc049-50f2-4be6-a598-2d7079358329',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 183,
	// 			cy: 108,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '0d5e8d7d-ed09-4a6b-8b4d-3d4bd82bd226',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 193,
	// 			cy: 120,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 		{
	// 			id: '2591f718-3616-4b1f-9764-ca69f4453a43',
	// 			r: 12,
	// 			rx: null,
	// 			width: null,
	// 			height: null,
	// 			stroke: '#dddddd',
	// 			strokeWidth: 0,
	// 			opacity: 1,
	// 			htmlName: 'circle',
	// 			transform: 'translate(0, 0)',
	// 			transformOrigin: '50% 50%',
	// 			cx: 99,
	// 			cy: 127,
	// 			x: null,
	// 			y: null,
	// 			scale: null,
	// 			shape: 'circle',
	// 			fill: '#915959',
	// 		},
	// 	],
	// ],
]
