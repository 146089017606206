import React, { useContext, useState, Fragment } from 'react'
import styled from 'styled-components'
import { Context } from '../../../App'
import { add } from '../../../helpers/canvas'
import LivePreview from '../LivePreview'
import { SVGImport } from '../SVGImport'

const StyledToolWrapper = styled.div`
	> button,
	> label {
		margin: 1rem;
	}
`

const Controls = ({ currentTimeSegment }) => {
	const [isSelected, setSelected] = useState(null)

	const context = useContext(Context)
	const {
		values: { transformControls },
	} = context

	const controls = transformControls.map(
		({ name, funcName, extraFuncs, icon }, i) => {
			return (
				<Fragment key={i}>
					<button
						onClick={() => {
							setSelected(funcName)
						}}
						key={funcName}
					>
						{name}
					</button>
					{isSelected === funcName && extraFuncs
						? extraFuncs.map((extraFunc, i) => (
								<Fragment key={i}>
									{/* {icon} */}
									<button
										onClick={() => {
											add(context, currentTimeSegment, extraFunc.funcName)
										}}
										key={extraFunc.funcName}
									>
										{extraFunc.name}
									</button>
								</Fragment>
						  ))
						: null}
					{/* {icon} */}
				</Fragment>
			)
		}
	)

	return (
		<StyledToolWrapper>
			<SVGImport currentTimeSegment={currentTimeSegment} />
			{controls} <LivePreview />
		</StyledToolWrapper>
	)
}

export default Controls
