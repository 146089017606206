import React, { Fragment, useContext, useState } from 'react'
import styled from 'styled-components'
import { Context } from '../../App'

const StyledSegment = styled.div`
	position: relative;
	cursor: pointer;
	> div {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		padding: 2rem;
		background-color: white;
		box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.46);
		bottom: 4.5rem;
		z-index: 1;
		cursor: auto;
		p:first-child {
			position: absolute;
			right: 1rem;
			top: 1rem;
			margin: 0;
			cursor: pointer;
		}
		p {
			color: black;
		}
		> button {
			cursor: pointer;
		}
		&:before {
			z-index: 0;
			content: '';
			position: absolute;
			text-align: center;
			bottom: -0.75rem;
			width: 0;
			height: 0;
			left: 50%;
			transform: translateX(-50%);
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #fff;
		}
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 2rem;
			left: 0;
			bottom: -2rem;
		}
	}
	&:hover {
		> div {
			opacity: 1;
			pointer-events: auto;
		}
	}
`

const StyledDuplicationForm = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Segment = ({ timeLine, className, id, name, onClick, style, index }) => {
	const {
		functions: {
			canvas: { duplicateSegment, removeSegment },
		},
	} = useContext(Context)
	const [clickedOnDuplicate, setClickedOnDuplicate] = useState(false)
	const [
		selectedSegmentToDuplicateAfter,
		setSelectedSegmentToDuplicateAfter,
	] = useState(0)

	return (
		<StyledSegment
			className={`${className} `}
			id={id}
			onClick={onClick}
			style={style}
		>
			<>
				<p>Segment {index}</p>
				<div>
					{clickedOnDuplicate ? (
						<StyledDuplicationForm>
							<p onClick={() => setClickedOnDuplicate(false)}>x</p>
							<p>
								After which segment would you like to have a copy of this
								segment?
							</p>
							<select
								onChange={({ target: { value } }) => {
									setSelectedSegmentToDuplicateAfter(value)
								}}
							>
								{timeLine.map((segment, i) => (
									<option value={i}>after segment {i}</option>
								))}
							</select>
							<button
								onClick={() => {
									duplicateSegment(index, selectedSegmentToDuplicateAfter)
								}}
							>
								Confirm
							</button>
						</StyledDuplicationForm>
					) : (
						<>
							<button onClick={() => setClickedOnDuplicate(true)}>
								Duplicate
							</button>
							{timeLine.length > 1 && index !== 0 ? (
								<button
									onClick={(e) => {
										e.stopPropagation()
										removeSegment(index)
									}}
								>
									Remove
								</button>
							) : null}
						</>
					)}
				</div>
			</>
		</StyledSegment>
	)
}

export default Segment
