import React, { useContext } from 'react'
import { Context } from '../../App'
import JsxParser from 'react-jsx-parser'
import styled from 'styled-components'
import { generateLivePreview } from '../../helpers/livePreview'

const StyledSvg = styled.svg`
	background-color: white;
`

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	border: 5px solid white;
	border-radius: 10px;
	align-items: center;
	background: white;
	> h3 {
		margin: 5px 0 0 0;
	}
	> p {
		margin: 0;
	}
`

const StyledButton = styled.button`
	width: 200px;
`

const width = 300
const height = 300

const Card = ({ timeLine, info }) => {
	const {
		values,
		functions: {
			setMultipleValues,
			animation: { calculatedurationPerSegment },
		},
	} = useContext(Context)

	const durationPerSegment = []

	const newdurationPerSegment = 1000 / timeLine.length

	for (let index = 0; index < timeLine.length - 1; index++) {
		durationPerSegment.push(newdurationPerSegment)
	}

	const livePreview = generateLivePreview(
		timeLine,
		durationPerSegment,
		{ width: 300, height: 300 },
		1000,
		'infinite',
		null,
		true
	)

	return (
		<StyledCard>
			<h3>{info.name}</h3>
			<p>by: {info.creator}</p>
			<StyledSvg width={300} height={300} viewBox='0 0 300 300'>
				<>
					<style>{livePreview.style}</style>
					<JsxParser renderInWrapper={false} jsx={livePreview.content} />
				</>
			</StyledSvg>
			<StyledButton
				onClick={() => {
					setMultipleValues({
						canvas: { ...values.canvas, timeLine },
						isHomePage: false,
						animation: {
							...values.animation,
							durationPerSegment: calculatedurationPerSegment(
								timeLine.length - 1
							),
						},
					})
				}}
			>
				Check out Template
			</StyledButton>
		</StyledCard>
	)
}

export default Card
