import React, { Fragment } from 'react'
import styled from 'styled-components'
import { generateHTML } from '../../helpers/export'
import './timeline.css'
import Segment from './Segment'

const StyledTimeLine = styled.div`
	bottom: 0;
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem 5%;
	background: rgb(151, 111, 251);
	background: linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 100%
	);
	transform: translateY(
		${({ codeIsVisible }) => (codeIsVisible ? '0' : '16rem')}
	);
	transition: transform 0.3s;
	z-index: 2;
`

const StyledTimeSegments = styled.div`
	position: relative;
	width: 100%;
	display: flex;
`

const StyledTimeDuration = styled.div`
	display: flex;
	flex-direction: row-reverse;
`

const StyledCodeBox = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	& textarea {
		width: 100%;
		height: 200px;
	}
	> div {
		width: 33%;
	}
`

const StyledTextAreaWrapper = styled.div`
	position: relative;
	&.copied {
		::after {
			transform: translateX(-50%) scaleY(1);
		}
	}
	::after {
		background: linear-gradient(
			144deg,
			rgba(151, 111, 251, 1) 0%,
			rgba(188, 0, 255, 1) 100%
		);
		color: white;
		content: 'copied!';
		position: absolute;
		background-color: lime;
		left: 50%;
		transform: translateX(-50%) scaleY(0);
		transform-origin: top;
		padding: 1rem;
		top: 1px;
		font-weight: bold;
		border-radius: 0 0 10px 10px;
		transition: transform 0.3s;
	}
`

const StyledDarkClickBox = styled.div`
	pointer-events: ${({ codeIsVisible }) => (codeIsVisible ? 'all' : 'none')};
	position: absolute;
	background-color: ${({ codeIsVisible }) =>
		codeIsVisible ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.0)'};
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	transition: background-color 0.3s;
`

const StyledTextArea = styled.textarea``

const TimeLine = ({
	timeLine,
	currentTimeSegment,
	setCurrentTimeSegment,
	durationPerSegment,
	totalTimeAnimation,
	changeAnimationDuration,
	setAnimationType,
	animationType,
	codeIsVisible,
	canvasSize,
	setCodeIsVisible,
}) => {
	const code = generateHTML(
		timeLine,
		durationPerSegment,
		{ height: 200, width: 200 },
		totalTimeAnimation,
		animationType,
		true
	)

	const copyContent = (e, id) => {
		e.target.select()
		document.execCommand('copy')
		document.getElementById(id).classList.add('copied')
		setTimeout(() => {
			document.getElementById(id).classList.remove('copied')
		}, 1500)
	}

	const { width, height } = canvasSize

	return (
		<>
			<StyledDarkClickBox
				codeIsVisible={codeIsVisible}
				onClick={() => {
					setCodeIsVisible(false)
				}}
			/>
			<StyledTimeLine codeIsVisible={codeIsVisible}>
				<StyledTimeDuration>
					<input
						id='totalTimeAnimation'
						type='number'
						onChange={changeAnimationDuration}
						defaultValue={1000}
						disabled
					/>
					<label htmlFor='totalTimeAnimation'>time</label>
				</StyledTimeDuration>
				<StyledTimeDuration>
					<select
						value={animationType}
						id='animationForm'
						onChange={(e) => setAnimationType(e.target.value)}
					>
						<option value='infinite'>Infinite</option>
						<option value='forwards'>Forwards</option>
					</select>
					<label htmlFor='animationForm'>Choose a animation type</label>
				</StyledTimeDuration>
				<StyledTimeSegments>
					{timeLine.map((segment, i) => (
						<Fragment key={i}>
							<Segment
								timeLine={timeLine}
								// Later change to (durationPerSegment[i] / totalTimeAnimation)
								style={{
									width: `${timeLine.length * 100}%`,
								}}
								className={currentTimeSegment === i ? 'selected' : 'segment'}
								onClick={() => setCurrentTimeSegment(i)}
								id={`segment${i}`}
								key={`segment${i}`}
								index={i}
							/>
						</Fragment>
					))}
				</StyledTimeSegments>
				<StyledCodeBox>
					<div>
						<h4>HTML</h4>
						<StyledTextAreaWrapper id='html'>
							<StyledTextArea
								readOnly
								value={`
<svg width="${width}" height="${height}" viewbox="0 0 ${width} ${height}">
${code.content}
</svg>`}
								onClick={(e) => {
									copyContent(e, 'html')
								}}
							/>
						</StyledTextAreaWrapper>
					</div>
					<div>
						<h4>CSS</h4>
						<StyledTextAreaWrapper id='css'>
							<StyledTextArea
								readOnly
								value={code.style}
								onClick={(e) => {
									copyContent(e, 'css')
								}}
							/>
						</StyledTextAreaWrapper>
					</div>
					<div>
						<h4>JS</h4>
						<StyledTextAreaWrapper id='js'>
							<StyledTextArea
								readOnly
								onClick={(e) => {
									copyContent(e, 'js')
								}}
							/>
						</StyledTextAreaWrapper>
					</div>
				</StyledCodeBox>
			</StyledTimeLine>
		</>
	)
}

export default TimeLine
