import React, { useContext } from 'react'
import styled from 'styled-components'
import { Context } from '../../App'
import './shapeMenu.css'

const StyledMenuItem = styled.div`
	margin-bottom: 2px;
`

const StyledShapeMenu = styled.div`
	z-index: 1;
	top: ${({ top, containerHeight }) => (top ? top + containerHeight / 2 : 0)}px;
	left: ${({ left, containerWidth }) =>
		left && containerWidth ? left + containerWidth + 10 : 0}px;
	transform: translateY(-50%);
`

const StyledLayerButton = styled.button`
	font-size: 14;
	font-weight: 900;
`

const ShapeMenu = ({ selected, isOpen, shiftLayerUp }) => {
	const {
		shapeMenu: { updateShape, copyShape, removeShape },
	} = useContext(Context).functions

	// https://css-tricks.com/snippets/css/css-triangle/

	if (selected) {
		const {
			width,
			height,
			id,
			r,
			shape,
			fill,
			x,
			cx,
			y,
			cy,
			rx,
			stroke,
			strokeWidth,
			// rotate,
			opacity,
			scale,
		} = selected

		const shapeId = id

		const {
			top,
			left,
			width: containerWidth,
			height: containerHeight,
		} = document.getElementById('svgBackground').getBoundingClientRect()

		return (
			<StyledShapeMenu
				className='shapeMenu'
				top={top}
				left={left}
				containerWidth={containerWidth}
				containerHeight={containerHeight}
			>
				{shape === 'circle' ? (
					<>
						<StyledMenuItem>
							<label htmlFor='r'>Radius</label>
							<input
								id='r'
								type='number'
								value={r}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						<StyledMenuItem>
							<label htmlFor='cx'>x</label>
							<input
								id='cx'
								type='number'
								value={cx}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						<StyledMenuItem>
							<label htmlFor='cy'>y</label>
							<input
								id='cy'
								type='number'
								value={cy}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
					</>
				) : (
					<>
						<StyledMenuItem>
							<label htmlFor='x'>x</label>
							<input
								id='x'
								type='number'
								value={x}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						<StyledMenuItem>
							<label htmlFor='y'>y</label>
							<input
								id='y'
								type='number'
								value={y}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
					</>
				)}
				{shape === 'square' ? (
					<>
						<StyledMenuItem>
							<label htmlFor='width'>Width</label>
							<input
								id='width'
								type='number'
								value={width}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						<StyledMenuItem>
							<label htmlFor='height'>Height</label>
							<input
								id='height'
								type='number'
								value={height}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						<StyledMenuItem>
							<label htmlFor='rx'>border radius</label>
							<input
								id='rx'
								type='number'
								value={rx}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem>
						{/* <StyledMenuItem>
							<label htmlFor='rotate'>rotation</label>
							<input
								id='rotate'
								type='number'
								value={rotate}
								onChange={(e) => {
									updateShape(e, shapeId)
								}}
							/>
						</StyledMenuItem> */}
					</>
				) : null}
				{shape === 'svg' ? (
					<StyledMenuItem>
						<label htmlFor='scale'>Scale</label>
						<input
							id='scale'
							type='number'
							value={Math.round(scale * 100)}
							onChange={(e) => {
								updateShape(e, shapeId)
							}}
						/>
					</StyledMenuItem>
				) : null}
				<StyledMenuItem>
					<label htmlFor='strokeWidth'>border size</label>
					<input
						id='strokeWidth'
						type='number'
						value={strokeWidth}
						onChange={(e) => {
							updateShape(e, shapeId)
						}}
					/>
				</StyledMenuItem>
				<StyledMenuItem>
					<label htmlFor='stroke'>border color</label>
					<input
						id='stroke'
						type='color'
						value={stroke}
						onChange={(e) => {
							updateShape(e, shapeId)
						}}
					/>
				</StyledMenuItem>
				<StyledMenuItem>
					<label htmlFor='fill'>color</label>
					<input
						id='fill'
						type='color'
						value={fill}
						onChange={(e) => {
							updateShape(e, shapeId)
						}}
					/>
				</StyledMenuItem>
				<StyledMenuItem>
					<label htmlFor='opacity'>opacity</label>
					<input
						id='opacity'
						type='number'
						value={opacity * 100}
						onChange={(e) => {
							updateShape(e, shapeId)
						}}
					/>
				</StyledMenuItem>
				<StyledMenuItem>
					<label htmlFor='opacity'>Layer</label>
					<div>
						<StyledLayerButton onClick={() => shiftLayerUp(true)}>
							↑
						</StyledLayerButton>
						<StyledLayerButton onClick={() => shiftLayerUp(false)}>
							↓
						</StyledLayerButton>
					</div>
				</StyledMenuItem>
				<StyledMenuItem id='copyNremove'>
					<button onClick={() => copyShape(shapeId)}>Copy</button>
					<button onClick={() => removeShape(shapeId)}>Remove</button>
				</StyledMenuItem>
			</StyledShapeMenu>
		)
	} else return null
}

export default ShapeMenu
