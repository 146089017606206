import React, { useContext } from 'react'
import styled from 'styled-components'

import { add } from '../../helpers/canvas'
import { Context } from '../../App'

const SkewedLabel = styled.label`
	position: relative;
	display: inline-block;
	background: white;
	border: solid 1px black;
	padding: 2px 10px;
	transform: skew(0, -6deg);
	pointer-events: all;
	font-size: 14px;

	input {
		position: absolute;
		display: inline-block;
		width: 1px;
		height: 1px;
	}
`

export const SVGImport = ({ currentTimeSegment }) => {
	const context = useContext(Context)

	const FileChange = (e) => {
		var file = e.target.files[0]
		if (file.type === 'image/svg+xml') {
			var reader = new FileReader()
			reader.onload = function (evt) {
				const svg = evt.target.result
				const svgStartElement = svg.substr(0, svg.indexOf('>') + 1)
				const svgContent = svg.substr(
					svgStartElement.length,
					svg.indexOf('</svg>') - svgStartElement.length
				)
				add(context, currentTimeSegment, 'svg', svgContent)
			}
			reader.readAsText(file)
		}
	}

	return (
		<SkewedLabel>
			Import SVG
			<input
				type='file'
				name='svg'
				accept='image/svg+xml'
				onChange={FileChange}
			/>
		</SkewedLabel>
	)
}
