export const transformIcon = {
	select: null,
	addShape: (
		<svg width='104.537' height='104.507' viewBox='0 0 104.537 104.507'>
			<defs>
				<linearGradient
					id='linear-gradient'
					x1='0.5'
					x2='0.5'
					y2='1'
					gradientUnits='objectBoundingBox'
				>
					<stop offset='0' stop-color='#9672fb' />
					<stop offset='1' stop-color='#bc00ff' />
				</linearGradient>
			</defs>
			<g
				id='Shape_button'
				data-name='Shape button'
				transform='translate(-579 -924)'
			>
				<path
					id='Path_11'
					data-name='Path 11'
					d='M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z'
					transform='translate(370.868 679.42)'
					fill='url(#linear-gradient)'
				/>
				<g
					id='Group_8'
					data-name='Group 8'
					transform='translate(498.634 676.312)'
				>
					<path
						id='Path_18'
						data-name='Path 18'
						d='M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z'
						fill='url(#linear-gradient)'
					/>
				</g>
			</g>
		</svg>
	),
	circle: (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='70'
			height='69.98'
			viewBox='0 0 70 69.98'
		>
			<defs>
				<linearGradient
					id='linear-gradient'
					x1='0.5'
					x2='0.5'
					y2='1'
					gradientUnits='objectBoundingBox'
				>
					<stop offset='0' stop-color='#9672fb' />
					<stop offset='1' stop-color='#bc00ff' />
				</linearGradient>
			</defs>
			<g
				id='Shape_button'
				data-name='Shape button'
				transform='translate(-579 -924)'
			>
				<path
					id='Path_11'
					data-name='Path 11'
					d='M208.132,279.527a35,35,0,1,1,34.919,35.033A35.067,35.067,0,0,1,208.132,279.527Zm65.908.067a30.908,30.908,0,1,0-30.951,30.875A30.995,30.995,0,0,0,274.04,279.593Z'
					transform='translate(370.868 679.42)'
					fill='url(#linear-gradient)'
				/>
				<path
					id='Path_25'
					data-name='Path 25'
					d='M208.132,267.142a22.6,22.6,0,1,1,22.543,22.617A22.639,22.639,0,0,1,208.132,267.142Zm42.55.043A19.954,19.954,0,1,0,230.7,287.118,20.01,20.01,0,0,0,250.682,267.185Z'
					transform='translate(382.868 692.231)'
					fill='url(#linear-gradient)'
				/>
			</g>
		</svg>
	),
	square: (
		<svg width='104.537' height='104.507' viewBox='0 0 104.537 104.507'>
			<defs>
				<linearGradient
					id='linear-gradient'
					x1='0.5'
					x2='0.5'
					y2='1'
					gradientUnits='objectBoundingBox'
				>
					<stop offset='0' stop-color='#9672fb' />
					<stop offset='1' stop-color='#bc00ff' />
				</linearGradient>
			</defs>
			<g
				id='Shape_button'
				data-name='Shape button'
				transform='translate(-579 -924)'
			>
				<path
					id='Path_11'
					data-name='Path 11'
					d='M208.132,296.769a52.268,52.268,0,1,1,52.147,52.317A52.369,52.369,0,0,1,208.132,296.769Zm98.426.1a46.158,46.158,0,0,0-92.315-.13c-.134,25.3,20.639,46.145,46.093,46.238A46.288,46.288,0,0,0,306.558,296.869Z'
					transform='translate(370.868 679.42)'
					fill='url(#linear-gradient)'
				/>
				<g
					id='Group_8'
					data-name='Group 8'
					transform='translate(498.634 676.312)'
				>
					<path
						id='Path_18'
						data-name='Path 18'
						d='M132.208,275.689h27.473c.223,0,.445,0,.667,0a1.685,1.685,0,0,1,1.769,1.821q0,22.063,0,44.126a1.7,1.7,0,0,1-1.57,1.822,5.031,5.031,0,0,1-.8.053q-27.506,0-55.013,0a5.09,5.09,0,0,1-.73-.042,1.724,1.724,0,0,1-1.64-1.9q0-22,0-43.993c0-1.277.687-1.894,2.1-1.894Q118.338,275.687,132.208,275.689Zm-26.3,3.522v40.72H158.59v-40.72Z'
						fill='url(#linear-gradient)'
					/>
				</g>
			</g>
		</svg>
	),
}
