import React, { useContext } from 'react'
import { Context } from '../../App'

const LivePreview = () => {
	const {
		canvas: { startLivePreview },
	} = useContext(Context).functions

	return <button onClick={startLivePreview}>LivePreview</button>
}

export default LivePreview
