import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import Shape from './Shape'
import JsxParser from 'react-jsx-parser'
import { Context } from '../../App'

const StyledSvg = styled.svg`
	background-color: white;
`

const StyledBackground = styled.div`
	background: ${({ livePreview }) =>
		!livePreview
			? `linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 100%
	);`
			: `linear-gradient(
		144deg, 
		rgba(151,111,251,1) 0%, 
		rgba(71,255,0,1) 100%);`};
	width: ${({ width }) => width + 10}px;
	height: ${({ height }) => height + 40}px;
	padding: 5px;
	position: absolute;
	transform: scale(${({ canvasScale }) => canvasScale});
	&::after {
		content: ' ';
		display: block;
		height: 55px;
		width: 80%;
		bottom: -2px;
		left: -5px;
		position: relative;
		background-color: white;
	}
`

const StyledSizeButtons = styled.div`
	position: absolute;
	right: 7px;
	bottom: 7px;
`

const StyledPlaceMent = styled.div`
	display: flex;
	justify-content: center;
`

const Canvas = ({
	timeLine,
	currentTimeSegment,
	canvasSize: { width, height },
	livePreview,
	canvasScale,
	setCanvasScale,
}) => {
	const { functions, values } = useContext(Context)
	const { shapeMenu, setMultipleValues } = functions
	const { mouseDown, shapeInfo } = values
	const { shapeId, shapeType, shapeWidth, shapeHeight } = shapeInfo

	const canvasSizes =
		window.screen.width > 1500 ? ['1', '1.5', '2'] : ['0.75', '1', '1.25']

	const canvasRef = useRef()

	const changeCanvasScale = (plusOrMinus) => {
		if (plusOrMinus === '-' && canvasScale !== 0) {
			setCanvasScale(canvasScale - 1)
		} else if (plusOrMinus === '+' && canvasScale !== canvasSizes.length - 1) {
			setCanvasScale(canvasScale + 1)
		}
	}

	const mouseMove = (e) => {
		if (mouseDown) {
			shapeMenu.dragShape(
				(e.clientX -
					canvasRef.current.getBoundingClientRect().left -
					(shapeType === 'square' ? shapeWidth / 2 : 0)) *
					(1 / canvasSizes[canvasScale]),
				(e.clientY -
					canvasRef.current.getBoundingClientRect().top -
					(shapeType === 'square' ? shapeHeight / 2 : 0)) *
					(1 / canvasSizes[canvasScale]),
				shapeId
			)
		}
	}

	const livePreviewRender = !livePreview ? (
		timeLine[currentTimeSegment].map((shapes, i) => {
			return <Shape {...shapes} />
		})
	) : (
		<>
			<style>{livePreview.style}</style>
			<JsxParser renderInWrapper={false} jsx={livePreview.content} />
		</>
	)

	return (
		<StyledPlaceMent>
			<StyledBackground
				id='svgBackground'
				canvasScale={canvasSizes[canvasScale]}
				width={width}
				height={height}
				livePreview={livePreview}
			>
				<StyledSvg
					width={width}
					height={height}
					onMouseMove={mouseMove}
					onMouseUp={() => {
						setMultipleValues({
							mouseDown: false,
							shapeInfo: {
								shapeId: null,
								shapeType: null,
								shapeWidth: width,
								shapeHeight: height,
							},
						})
					}}
					ref={canvasRef}
				>
					{livePreviewRender}
				</StyledSvg>
				<StyledSizeButtons>
					<div>
						<button onClick={() => changeCanvasScale('-')}>-</button>
						<button onClick={() => changeCanvasScale('+')}>+</button>
					</div>
				</StyledSizeButtons>
			</StyledBackground>
		</StyledPlaceMent>
	)
}

export default Canvas
