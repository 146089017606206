export const gettingStartedModals = [
	{
		title: 'Getting started',
		text: `Welcome to BouncyThings! If you need help getting started, please click the next button below. If not, close this modal`,
		buttonText: `Next`,
	},
	{
		title: 'The Timeline',
		text: `The timeline below indicates how many segments are in the micro-animation. Right now there's only one segment, called "Segment 0". This is the initial state of your animation. First add some shapes to the canvas, by using the "add shape" button above from the timeline. Once you're done creating the initial animation segment. Click Add segment. It copies the previous segment. Select shapes to change the prefered values. Once you're happy, you can either create an extra segment, or export your current animation`,
		buttonText: `Get Started`,
	},
]
