import React, { useContext } from 'react'
import styled from 'styled-components'
import { Context } from '../../../App'

const StyledButton = styled.button`
	background: linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 100%
	);
	color: white;
	border: none;
	font-weight: 900;
`

const Record = () => {
	const {
		canvas: { addTimeSegment },
	} = useContext(Context).functions
	return <StyledButton onClick={addTimeSegment}>Add Segment</StyledButton>
}

export default Record
