import React from 'react'
import styled from 'styled-components'

import { templates } from '../../helpers/templates'
import Card from './Card'

const StyledMain = styled.div`
	display: flex;
	height: ${({ background }) => (background === 'gradient' ? 'auto' : '80vh')};
	flex-direction: ${({ background }) =>
		background === 'gradient' ? 'column' : 'auto'};
	justify-content: space-between;
	margin-top: 3rem;
	background: ${({ background }) =>
		background === 'gradient'
			? `linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 40%
	);`
			: `white`};
	padding-bottom: ${({ background }) =>
		background === 'gradient' ? '25vh' : '0'};
`

const StyledMainItem = styled.div`
	height: 100%;
	padding: 0 5%;
	width: 100%;
	text-align: center;
	display: grid;
	place-content: center;
	> div {
		align-items: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	> h1 {
		font-size: 22px;
	}
	& em {
		color: #9672fb;
	}
`

const StyledBackground = styled.div`
	> svg {
		width: 100%;
	}
`

const StyledGetStartedButton = styled.div`
	cursor: pointer;
	width: 50%;
	background: linear-gradient(
		144deg,
		rgba(151, 111, 251, 1) 0%,
		rgba(188, 0, 255, 1) 40%
	);
	color: white;
	padding: 1rem;
	border-radius: 10px;
`

const StyledCardContainer = styled.div`
	width: 100%;
	margin: 5% 5% 0 5%;
	display: flex;
	justify-content: space-between;
	height: fit-content;
`

const StyledHeader = styled.h2`
	width: 100%;
	position: absolute;
	text-align: center;
	color: white;
	margin-top: ${({ first }) => (first ? '5rem' : 'auto')};
`

const StyledRow = styled.div`
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
`
const StylediFrame = styled.iframe`
	box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.46);
	width: 60vw;
	height: 34vw;
	margin: 10rem 0;
	& * {
		border: 0px black solid !important;
	}
`
// const generateSVG = () => {
//     timeLine,
// 	durationPerSegment,
// 	canvasSize,
// 	totalTimeAnimation,
// 	animationType
// }

const templateInfo = [
	{
		name: 'Scroll',
		creator: 'Emiel Muis',
	},
	{ name: 'Brand color loader', creator: 'Emiel Muis' },
	{ name: 'Nike Logo', creator: 'Emiel Muis' },
	{ name: 'Interactive Buttons', creator: 'Emiel Muis' },
]

const HomePage = ({ values, setValues }) => {
	return (
		<>
			<StyledMain>
				<StyledMainItem>
					<div>
						<h1>
							<em>Design your own</em> <br /> micro-animations like never before
							<br />
						</h1>
						<StyledGetStartedButton
							onClick={() => {
								setValues({
									...values,
									isHomePage: false,
								})
							}}
						>
							Get Started
						</StyledGetStartedButton>
					</div>
				</StyledMainItem>
				<StyledMainItem>
					<StyledBackground>
						<svg width='997.115' height='968.598' viewBox='0 0 997.115 968.598'>
							<defs>
								<linearGradient
									id='linear-gradient'
									x1='0.272'
									y1='0.119'
									x2='0.62'
									y2='1'
									gradientUnits='objectBoundingBox'
								>
									<stop offset='0' stop-color='#9672fb' />
									<stop offset='1' stop-color='#d772fb' />
								</linearGradient>
							</defs>
							<path
								id='Mask_BG'
								data-name='Mask BG'
								d='M497.373,0C650.109,0,813.319,151.708,813.319,304.786S689.5,581.959,536.766,581.959,0,401.668,0,248.589,344.637,0,497.373,0Z'
								transform='translate(374.076) rotate(40)'
								fill='url(#linear-gradient)'
							/>
						</svg>
					</StyledBackground>
				</StyledMainItem>
			</StyledMain>
			<StyledMain background='gradient' id='tutorial'>
				<StyledRow>
					<StyledHeader first>Tutorial</StyledHeader>
					<StylediFrame
						title='BouncyThings tutorial'
						src='https://www.youtube.com/embed/0QH2SNdEGw4'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowfullscreen
					></StylediFrame>
				</StyledRow>
				<StyledRow>
					<StyledHeader id='templates'>Templates</StyledHeader>
					<StyledCardContainer>
						{templates.map((timeLine, i) => {
							return <Card timeLine={timeLine} info={templateInfo[i]} key={i} />
						})}
					</StyledCardContainer>
				</StyledRow>
			</StyledMain>
		</>
	)
}

export default HomePage
