import React, { useContext } from 'react'
import { Context } from '../../App'
import './shape.css'

const Shape = ({
	id,
	shape,
	width,
	height,
	fill,
	r,
	stroke,
	strokeWidth,
	x,
	rx,
	cx,
	cy,
	y,
	opacity,
	htmlName,
	transform,
	transformOrigin,
	content,
	scale,
	// rotate,
}) => {
	const { shapeMenu, setMultipleValues } = useContext(Context).functions

	if (shape === 'circle') {
		return (
			<circle
				className='animate'
				onMouseDown={() => {
					setMultipleValues({
						mouseDown: true,
						shapeInfo: {
							shapeId: id,
							shapeType: shape,
						},
					})
				}}
				onMouseUp={() => {
					setMultipleValues({
						mouseDown: false,
						shapeInfo: {
							shapeId: null,
							shapeType: null,
						},
					})
				}}
				onClick={(e) => shapeMenu.open(e.target.id)}
				r={r}
				id={id}
				key={id}
				cx={cx}
				cy={cy}
				htmlName={htmlName}
				fill={fill}
				stroke={stroke}
				strokeWidth={strokeWidth}
				opacity={opacity}
			></circle>
		)
	} else if (shape === 'square') {
		return (
			<rect
				className='animate'
				onMouseDown={() => {
					setMultipleValues({
						mouseDown: true,
						shapeInfo: {
							shapeId: id,
							shapeType: shape,
							shapeWidth: width,
							shapeHeight: height,
						},
					})
				}}
				onClick={(e) => shapeMenu.open(e.target.id)}
				id={id}
				key={id}
				x={x}
				y={y}
				htmlName={htmlName}
				rx={rx}
				width={width}
				fill={fill}
				stroke={stroke}
				// rotate={rotate}
				strokeWidth={strokeWidth}
				height={height}
				opacity={opacity}
				transformOrigin={transformOrigin}
			></rect>
		)
	} else if (shape === 'svg') {
		return (
			<g
				dangerouslySetInnerHTML={{
					__html: content,
				}}
				style={{
					transform: `translate(${x}px, ${y}px) scale(${scale})`,
					transformOrigin: transformOrigin,
				}}
				className='animate'
				onMouseDown={() => {
					setMultipleValues({
						mouseDown: true,
						shapeInfo: {
							shapeId: id,
							shapeType: shape,
							shapeWidth: width,
							shapeHeight: height,
						},
					})
				}}
				onClick={(e) => shapeMenu.open(e.target.id)}
				id={id}
				key={id}
				x={x}
				y={y}
				fill={fill}
				transform={transform}
				htmlName={htmlName}
				width={width}
				// svgWidth={svgWidth}
				// svgHeight={svgHeight}
				height={height}
				scale={scale}
				opacity={opacity}
				transformOrigin={transformOrigin}
			/>
		)
	}
}

export default Shape
