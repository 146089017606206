import React from 'react'
import { generateDownload } from '../../../helpers/export'
import styled from 'styled-components'

const StyledExportContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	margin: ${({ screenWidth }) =>
		screenWidth > 1500 ? '4rem 5%' : '1rem 5% 0 0'};
	> div {
		background: linear-gradient(
			144deg,
			rgba(151, 111, 251, 1) 0%,
			rgba(188, 0, 255, 1) 100%
		);
		padding: 1rem;
		border: none;
		width: 200px;
		border-radius: 10px;
		color: white;
		font-weight: 900;
	}
`

const StyledClickableDiv = styled.div`
	cursor: pointer;
`

const Export = ({
	timeLine,
	durationPerSegment,
	canvasSize,
	totalTimeAnimation,
	animationType,
	setCodeIsVisible,
	codeIsVisible,
}) => {
	const screenWidth = window.screen.width

	return (
		<StyledExportContainer screenWidth={screenWidth}>
			<div>
				<StyledClickableDiv
					onClick={() => {
						generateDownload(
							timeLine,
							durationPerSegment,
							canvasSize,
							totalTimeAnimation,
							animationType
						)
					}}
				>
					Download HTML
				</StyledClickableDiv>
				<hr />
				<StyledClickableDiv onClick={() => setCodeIsVisible(!codeIsVisible)}>
					View Code
				</StyledClickableDiv>
			</div>
		</StyledExportContainer>
	)
}

export default Export
